import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'

const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export const SocketProvider = ({ token, children }) => {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    if (token) {
      const SOCKET_SERVER_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
      const newSocket = io(SOCKET_SERVER_URL, {
        auth: { token },
        transports: ['websocket']
      })

      newSocket.on('connect', () => {
        console.log('Socket connected!')
      })

      newSocket.on('disconnect', () => {})

      newSocket.on('message', data => {
        console.log(data)
      })

      newSocket.on('inwise-status', data => {
        if (data) {
          if (data.r.error) {
            console.log('data contains an error')
            // Log error details if the message contains an error object
            console.error(data.r.error)
          } else {
            console.log(data)
            // Log normal data if no error is present
          }
        } else {
          console.log(data)
        }
      })

      newSocket.on('error', data => {
        console.error('A socket.io error occurred:', data)
      })

      newSocket.on('connect_error', error => {
        console.error('Connection error:', error)
      })

      setSocket(newSocket)

      // Clean up the socket when the component unmounts or when token changes
      return () => {
        newSocket.disconnect()
      }
    }
  }, [token]) // Add token to the dependency array

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}
