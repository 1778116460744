import React, { useContext, useEffect, useRef, useState } from 'react'
import './Selection.css'
import ActionButton from '../Buttons/ActionButton'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'

const Selection = React.forwardRef(({}, ref) => {
  const { curSession, selectedLanguage, setStageByName } = useContext(SessionContext)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({})
  const [imageURL, setImageURL] = useState(null)

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])

  useEffect(() => {
    if (curSession.selectedImageData) {
      const url = URL.createObjectURL(curSession.selectedImageData)
      setImageURL(url)

      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [curSession.selectedImageData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('selection')

    setSiteData(data)
    addNameAndYear(data)
  }

  const addNameAndYear = data => {
    if (data) {
      const newName = curSession.name
        ? `${data.title} ${curSession.name}`
        : `${data.title}${selectedLanguage === 'en' ? ' you' : 'ך'}`
      const newYear = `${data.text} ${new Date().getFullYear()}`

      setSiteData(prevData => ({
        ...prevData, // Spread existing data to maintain other properties
        title: newName, // Update title
        text: newYear // Update text
      }))
    }
  }

  const setEmailStage = () => {
    setStageByName('inputEmail')
  }

  const setShredStage = () => {
    //setStageByName('shred')
    setStageByName('resultsGallery')
  }

  return (
    <div ref={ref} style={{}}>
      <StageHeader siteData={siteData} />
      <div className='selection-parent'>
        <div className='portrait-viewer-container'>
          {imageURL && <img className='portrait-viewer' src={imageURL} alt='Portrait' />}
        </div>
        <div className='action-buttons-parent'>
          <ActionButton buttonType={'standard shared-styles'} onClick={setEmailStage}>
            {siteData.buttonRight}
          </ActionButton>
          <ActionButton buttonType={'inverted shared-styles'} onClick={setShredStage}>
            {siteData.buttonLeft}
          </ActionButton>
        </div>
      </div>
    </div>
  )
})

export default Selection
