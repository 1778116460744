import React, { useContext, useReducer, useEffect, useRef, useState } from 'react'
import { useSocket } from '../../contexts/SocketContext'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import './LoadingPage.css'

const LoadingPage = React.forwardRef(({}, ref) => {
  const { updateSession, setStageByName, selectedLanguage, curSession, currentStage } =
    useContext(SessionContext)
  const socket = useSocket()
  const [imagesOutput, setImagesOutput] = useState([])
  const [currentImagesCount, setCurrentImagesCount] = useState(0)
  const imageEmitted = useRef(false)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({})
  const totalTime = 55 // total time in seconds

  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const intervalTime = 100 // interval time in milliseconds (update every 100ms)
  const [progress, setProgress] = useState(0)
  const [elapsedTime, setElapsedTime] = useState(0)

  const [focus, setFocus] = useState(true)

  const totalImagesExpected = 4
  const progressEl = useRef(null)
  const percentageEl = useRef(null)
  const [seconds, setSeconds] = useState(process.env.REACT_APP_GENERATION_TIMEOUT)
  const [isActive, setIsActive] = useState(false)
  const [jobID, setJobID] = useState('')
  const [shouldAdvance, setShouldAdvance] = useState(false)

  //static site data
  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('generating')
    setSiteData(data)
  }

  useEffect(() => {
    let interval
    if (currentStage.name === 'generating') {
      interval = setInterval(() => {
        setElapsedTime(prevTime => {
          const newTime = prevTime + intervalTime / 1000 // convert intervalTime to seconds
          if (newTime >= totalTime) {
            clearInterval(interval)
            return totalTime
          }
          return newTime
        })
      }, intervalTime)
    } else {
      //
    }

    return () => clearInterval(interval)
  }, [currentStage.name])

  useEffect(() => {
    // Generate a random number between 0 and 1
    const randomChance = Math.random()
    // Only update progress if the random chance is less than or equal to 0.2 (20%)
    if (randomChance <= 0.2) {
      setProgress((elapsedTime / totalTime) * 96)
    }
  }, [elapsedTime])

  //error handling
  const navigateToError = e => {
    resetComponent()
    setStageByName('errorPage')
    socket.emit('cancel_job', jobID)
    socket.emit('output_error', e)
    console.log('canceling job', jobID)
  }

  const handleConnectionError = e => {
    console.error('A socket econnection error occured', e)
    navigateToError(e)
  }

  const handlImageProcessingError = e => {
    console.error('an error occured trying to generate a user image', e)
    navigateToError({ message: 'an error occured trying to generate a user image', error: e })
  }

  const handleSDInitError = e => {
    //check if the current environment is development
    //if so, load mock images from disk and use them

    if (process.env.NODE_ENV === 'development') {
      //loadMockImages()
      navigateToError(e)
    } else {
      console.error(`The image generation server is offline, ${e}`)
      curSession.inputImageData = null
      navigateToError(e)
    }

    console.error('sd initialization error')
  }

  const loadMockImages = async () => {
    const basePath = `${process.env.REACT_APP_API_URL}/public/input-images/mock-images`
    //const imageNames = ['1.png', '2.png', '3.png', '4.png']
    const imageNames = ['1.jpg', '2.jpg', '3.jpg', '4.jpg']
    const imagePromises = imageNames.map((name, index) =>
      fetch(`${basePath}/${name}`)
        .then(response => response.blob())
        .then(blob => ({
          index,
          blob,
          url: URL.createObjectURL(blob)
        }))
        .catch(error => console.error(`Failed to load image ${name}:`, error))
    )

    const images = await Promise.all(imagePromises)

    setImagesOutput(images)
    setCurrentImagesCount(images.length)
  }

  //DEBUG!!!!!!!
  useEffect(() => {
    if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG === 'true'){
      setTimeout(() => {
        loadMockImages()
      }, 3000)
    }
  }, [])

  //user image emission logic
  useEffect(() => {
    if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG === 'true'){
      return
    }
    if (!imageEmitted.current && curSession.inputImageData) {
      imageEmitted.current = true
      fetch(curSession.inputImageData)
        .then(res => res.blob())
        .then(blob => {
          const reader = new FileReader()
          reader.readAsArrayBuffer(blob)
          reader.onload = () => {
            const buffer = reader.result
            socket.emit('image', buffer)
          }
        })
    }
  }, [curSession, imageEmitted.current])

  useEffect(() => {
    if (currentImagesCount === totalImagesExpected) {
      if (focus) {
        handleOutputSent()
        socket.off('image_output', handleImageOutput)
      } else {
        console.log('Waiting on tab to get back to focus before returning to the next stage')
        setShouldAdvance(true)
      }
    }
  }, [currentImagesCount])

  useEffect(() => {}, [focus])

  useEffect(() => {
    if (shouldAdvance && focus) {
      forceUpdate()
      handleOutputSent()
      socket.off('image_output', handleImageOutput)
      setShouldAdvance(false)
    }
  }, [shouldAdvance, focus])

  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    // Calls onFocus when the window first loads
    onFocus()
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  const handleJobStatus = status => {
    setJobID(status.id)
    console.log(`Job status: ${status.status}, ${status.id}`)
  }

  const handleImageOutput = data => {
    const blob = new Blob([data.image], { type: 'image/png' })
    const outputImage = { index: data.index, blob }

    setImagesOutput(prev => [...prev, outputImage])

    setCurrentImagesCount(prev => prev + 1)
  }

  const handleSocketDisconnect = () => {
    if (currentImagesCount !== totalImagesExpected && currentStage.name === 'generating') {
      console.error('Socket connection to the server was closed before all images arrived')
      navigateToError({ message: 'Socket connection to the server was closed before all images arrived' })
    } else {
      console.log('Socket connection to the server was properrly closed')
    }
  }

  // Handling incoming image data
  useEffect(() => {
    socket.on('sd_init_error', handleSDInitError)
    socket.on('image_output_error', handlImageProcessingError)
    socket.on('image_output', handleImageOutput)

    socket.on('job_status', handleJobStatus)
    socket.on('connect_error', handleConnectionError)
    socket.on('disconnect', handleSocketDisconnect)
    return () => {
      socket.off('connect_error', handleConnectionError)
      socket.off('sd_init_error', handleSDInitError)
      socket.off('image_output', handleImageOutput)
      socket.off('disconnect', handleSocketDisconnect)
      socket.off('job_status', handleJobStatus)
      socket.off('image_output_error', handlImageProcessingError)
    }
  }, [socket, imagesOutput]) // Only re-subscribe when `socket` changes

  useEffect(() => {
    if (currentStage.name === 'generating') {
      setSeconds(process.env.REACT_APP_GENERATION_TIMEOUT)
      setIsActive(true)
    }

    return () => {
      setIsActive(false)
    }
  }, [currentStage])

  //this is the timeout timer -
  useEffect(() => {
    let interval = null
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1)
      }, 1000)
    } else if (seconds === 0) {
      if (!shouldAdvance) {
        clearInterval(interval)
        console.log('generation times up')
        socket.emit('stop_generating')
        navigateToError({ message: 'Image generation has reached its timeout' })
        setIsActive(false) // Automatically stop the timer when it reaches zero
      }
    }
    return () => clearInterval(interval)
  }, [seconds, isActive, curSession, imageEmitted.current])

  //self explanatory
  const handleOutputSent = () => {
    updateSession({ outputImagesData: imagesOutput })
    socket.off('image_output', handleImageOutput)
    setProgress(100)
    socket.off()
    setStageByName('resultsGallery')
    setTimeout(() => {
      resetComponent()
    }, 1000)
  }

  //component reset
  const resetComponent = () => {
    //reset all dynamically set numbers
    setCurrentImagesCount(0)
    setProgress(0)
    setElapsedTime(0)
    //clear user taken image
    updateSession({ inputImageData: null })

    setIsActive(false)
    setSeconds(process.env.REACT_APP_GENERATION_TIMEOUT)

    imageEmitted.current = false
  }

  const handleSocketIOConnectionClosed = () => {
    if (currentStage.name === 'generating') {
      console.error('Socket io connection closed while generating images while generating images')
      if (currentImagesCount !== totalImagesExpected || !curSession.outputImagesData) {
        console.log('all conditions are met to go to the error page')
        navigateToError({
          message: 'Socket io connection closed while generating images while generating images'
        })
      } else {
        console.log('Socket io connection vlosed but all the images arrived')
        handleOutputSent()
      }
    }
  }

  //update loading bar
  useEffect(() => {
    let percentage

    if (currentStage.name === 'generating') {
      percentage = Math.floor(progress) + '%'

      if (progressEl.current) {
        progressEl.current.style.width = percentage
      }
      if (percentageEl.current) {
        percentageEl.current.innerText = percentage
        percentageEl.current.style.left = percentage
      }
    } else {
      percentage = 0
    }
  }, [progress, currentStage])

  return (
    <div ref={ref}>
      <StageHeader siteData={siteData}></StageHeader>
      <div className='loading-parent'>
        <video
          className='loading-video-parent'
          autoPlay={true}
          loop={true}
          src={`${process.env.REACT_APP_PUBLIC_URL}videos/portrait-generator-loader.mp4`}
          playsInline
          controls={false}
          muted
        ></video>
        <div className='progress-container' data-percentage='70'>
          <div className='progress' ref={progressEl}></div>
          <div className='percentage' ref={percentageEl}>
            {Math.floor(progress)}
          </div>
        </div>
      </div>
    </div>
  )
})
export default LoadingPage
