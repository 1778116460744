import React, { useContext, useEffect, useRef, useState } from 'react'
import './EmailSuccess.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import ActionButton from '../Buttons/ActionButton'
import StageHeader from '../StageHeader/StageHeader'

const EmailSuccess = React.forwardRef(({}, ref) => {
  const { fetchSiteData } = useContext(SiteDataContext)
  const { setStageByName, curSession, selectedLanguage, deleteSession } = useContext(SessionContext)
  const [siteData, setSiteData] = useState({})
  const [details, setDetails] = useState({})
  const [imageUrl, setImageUrl] = useState(null)
  const currentUrl = useRef(null)

  useEffect(() => {
    if (curSession.isUnderage && !curSession.passcode) {
      handleSiteData('parentFormSuccess')
    } else {
      handleSiteData('emailSuccess')
    }
    getCardDetails()
  }, [selectedLanguage])

  useEffect(() => {
    if (curSession.selectedImageData) {
      const url = URL.createObjectURL(curSession.selectedImageData)
      setImageUrl(url)

      if (currentUrl.current) {
        URL.revokeObjectURL(currentUrl.current)
      }

      currentUrl.current = url
    }
    return () => {
      if (currentUrl.current) {
        URL.revokeObjectURL(currentUrl.current)
      }
      URL.revokeObjectURL(imageUrl)
    }
  }, [curSession.selectedImageData])

  const getCardDetails = async () => {
    const details = await fetchSiteData('selection')
    setDetails(details)
    addNameAndYear(details)
  }

  const addNameAndYear = data => {
    if (curSession.isUnderage && !curSession.passcode) {
      return
    }

    if (data && curSession.form && curSession.form.firstName) {
      const newName = curSession.form.firstName
        ? `${data.title} ${curSession.form.firstName}`
        : `${data.title}${selectedLanguage === 'en' ? ' you' : 'ך'}`
      const newYear = `${data.text} ${new Date().getFullYear()}`

      setDetails(prevData => ({
        ...prevData, // Spread existing data to maintain other properties
        title: newName, // Update title
        text: newYear // Update text
      }))
    }
  }

  const addEmailAddress = data => {
    if (data) {
      let newText

      if (!curSession.form) return

      if (selectedLanguage === 'en') {
        newText = curSession.form.email ? `${data.text} ${curSession.form.email}` : `${data.text} your email`
      } else {
        newText = curSession.form.email ? `${data.text} ${curSession.form.email}` : `${data.text}`
      }

      setSiteData(prevData => ({
        ...prevData,
        text: newText
      }))
    }
  }

  const handleSiteData = async name => {
    const data = await fetchSiteData(name)

    if (data) {
      setSiteData(data)
      if (!curSession.isUnderage || curSession.passcode) {
        addEmailAddress(data)
      }
    }
  }

  const goHome = () => {
    setStageByName('home')
    setTimeout(() => {
      deleteSession()
    }, 1000)
    //clear session here
  }

  return (
    <div ref={ref} className='email-success-container'>
      {curSession.isUnderage && !curSession.passcode && (
        <>
          <StageHeader siteData={siteData} renderOnly='title'></StageHeader>
          <StageHeader
            siteData={{ text: curSession.form.email ? curSession.form.email : '' }}
            renderOnly='text'
          ></StageHeader>
          <StageHeader siteData={siteData} renderOnly='text'></StageHeader>
        </>
      )}
      {(!curSession.isUnderage || curSession.passcode) && (
        <>
          <StageHeader siteData={siteData}></StageHeader>
        </>
      )}
      <div className='horizontaly-center'>
        {(!curSession.isUnderage || curSession.passcode) && (
          <div className='end-card-parent'>
            <img
              className='end-card'
              src={`${
                currentUrl.current
                  ? currentUrl.current
                  : process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEBUG === 'true'
                  ? `${process.env.REACT_APP_API_URL}/public/input-images/mock-images/1.jpg`
                  : currentUrl.current
              }`}
            ></img>
            <div className='end-details-parent'>
              <div className='end-card-title'>{details.title}</div>
              <div className='end-card-text'>{details.text}</div>
            </div>
          </div>
        )}
        <button onClick={goHome} className='home-button'>
          {siteData.buttonRight}
          <svg
            width='2.5vh'
            height='2.5vh'
            viewBox='0 0 57 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g id='Icon/ Utility / Replay'>
              <path
                id='Vector'
                d='M31.8325 7.00078C21.0286 7.00078 12.216 15.6229 11.866 26.3424L8.01181 22.4882C7.71136 22.1873 7.30728 22.0127 6.88226 21.9999C6.65523 21.9934 6.42926 22.0334 6.21822 22.1173C6.00719 22.2013 5.81554 22.3275 5.65505 22.4882C5.34255 22.8008 5.16699 23.2247 5.16699 23.6666C5.16699 24.1086 5.34255 24.5325 5.65505 24.845L12.3217 31.5117C12.6343 31.8241 13.0581 31.9997 13.5001 31.9997C13.9421 31.9997 14.3659 31.8241 14.6785 31.5117L21.3452 24.845C21.6577 24.5325 21.8332 24.1086 21.8332 23.6666C21.8332 23.2247 21.6577 22.8008 21.3452 22.4882C21.0326 22.1757 20.6087 22.0002 20.1667 22.0002C19.7248 22.0002 19.3009 22.1757 18.9883 22.4882L15.2025 26.274C15.584 17.3911 22.8541 10.3333 31.8333 10.3333C41.0577 10.3333 48.4999 17.7754 48.4999 26.9999C48.4999 36.2243 41.0577 43.6665 31.8333 43.6665C26.1935 43.6665 20.9417 40.8185 17.8652 36.0917C17.7458 35.9079 17.5914 35.7495 17.4108 35.6255C17.2301 35.5015 17.0268 35.4143 16.8124 35.3689C16.5981 35.3236 16.3768 35.3209 16.1615 35.3612C15.9461 35.4014 15.7407 35.4837 15.5572 35.6034C15.1875 35.8449 14.9288 36.2233 14.8379 36.6554C14.7469 37.0875 14.8312 37.538 15.0721 37.908C18.7613 43.5765 25.0699 46.9998 31.8337 46.9998C42.8597 46.9998 51.8337 38.0259 51.8337 26.9999C51.8337 15.974 42.8585 7.00078 31.8325 7.00078Z'
                fill='white'
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  )
})

export default EmailSuccess
