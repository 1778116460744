import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSocket } from '../../contexts/SocketContext'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import ActionButton from '../Buttons/ActionButton'

const ErrorPage = React.forwardRef(({}, ref) => {
  const { updateSession, selectedLanguage, setStageByName, deleteSession } = useContext(SessionContext)
  const socket = useSocket()
  const [imagesOutput, setImagesOutput] = useState([])
  const [totalImagesExpected, setTotalImagesExpected] = useState(null)
  const [currentImagesCount, setCurrentImagesCount] = useState(0)
  const imageEmitted = useRef(false)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({})

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('errorPage')
    if (data.title) {
      setSiteData(data)
    } else {

      let sd

      selectedLanguage === 'he'
        ? (sd = {
            title: 'אופס! משהו השתבש',
            text: 'נראה שישנה בעיה עם החיבור',
            buttonLeft: 'התחלה חדשה',
            buttonRight: 'ניסיון חוזר'
          })
        : (sd = {
            title: 'Oops! Something went wrong',
            text: 'There seems to be an issue with the connection',
            buttonLeft: 'Start Over',
            buttonRight: 'Try Again'
          })

      setSiteData(sd)
    }
  }

  const backToTakePhoto = () => {
    deleteSession()
    setStageByName('takePhoto')
  }

  const backToHome = () => {
    deleteSession()
    setStageByName('home')
  }

  return (
    <div ref={ref}>
      <StageHeader siteData={siteData}></StageHeader>
      <div className='action-buttons-parent'>
        <ActionButton
          buttonType={'standard shared-styles'}
          onClick={() => {
            backToTakePhoto()
          }}
          text={siteData.buttonRight}
        >
          <svg width='3vh' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='Icon/ Utility / Replay'>
              <path
                id='Vector'
                d='M31.3322 7.00078C20.5283 7.00078 11.7156 15.6229 11.3656 26.3424L7.51149 22.4882C7.21104 22.1873 6.80696 22.0127 6.38194 21.9999C6.15491 21.9934 5.92894 22.0334 5.7179 22.1173C5.50687 22.2013 5.31522 22.3275 5.15473 22.4882C4.84223 22.8008 4.66667 23.2247 4.66667 23.6666C4.66667 24.1086 4.84223 24.5325 5.15473 24.845L11.8214 31.5117C12.1339 31.8241 12.5578 31.9997 12.9998 31.9997C13.4417 31.9997 13.8656 31.8241 14.1782 31.5117L20.8448 24.845C21.1573 24.5325 21.3329 24.1086 21.3329 23.6666C21.3329 23.2247 21.1573 22.8008 20.8448 22.4882C20.5323 22.1757 20.1084 22.0002 19.6664 22.0002C19.2244 22.0002 18.8006 22.1757 18.488 22.4882L14.7022 26.274C15.0837 17.3911 22.3538 10.3333 31.333 10.3333C40.5574 10.3333 47.9996 17.7754 47.9996 26.9999C47.9996 36.2243 40.5574 43.6665 31.333 43.6665C25.6931 43.6665 20.4413 40.8185 17.3648 36.0917C17.2455 35.9079 17.0911 35.7495 16.9104 35.6255C16.7298 35.5015 16.5265 35.4143 16.3121 35.3689C16.0977 35.3236 15.8765 35.3209 15.6611 35.3612C15.4457 35.4014 15.2404 35.4837 15.0569 35.6034C14.6872 35.8449 14.4285 36.2233 14.3376 36.6554C14.2466 37.0875 14.3309 37.538 14.5718 37.908C18.261 43.5765 24.5696 46.9998 31.3334 46.9998C42.3594 46.9998 51.3333 38.0259 51.3333 26.9999C51.3333 15.974 42.3582 7.00078 31.3322 7.00078Z'
                fill='white'
              />
            </g>
          </svg>
        </ActionButton>
        <ActionButton buttonType={'inverted shared-styles'} onClick={backToHome} text={siteData.buttonLeft}>
          <svg width='3vh' height='56' viewBox='0 0 57 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='Icon/ Essential/Home'>
              <g id='Vector'>
                <path
                  d='M42.29 51.3334H14.71C10.72 51.3334 7.5 48.02 7.5 43.96V23.66C7.5 20.6967 8.85336 17.9434 11.2334 16.1234L24.44 5.9734C26.9133 4.27007 30.0867 4.27007 32.4667 5.9034L45.7667 16.1234C48.1467 17.9434 49.5 20.6967 49.5 23.66V43.96C49.5 48.02 46.2567 51.3334 42.29 51.3334ZM28.5467 9.3334C28.08 9.3334 27.59 9.4734 27.17 9.7534L14.08 19.8334C12.8667 20.7667 12.1667 22.1667 12.1667 23.66V43.96C12.1667 45.4534 13.31 46.6667 14.71 46.6667H42.29C43.69 46.6667 44.8333 45.4534 44.8333 43.96V23.66C44.8333 22.1434 44.1333 20.7667 42.92 19.8334L29.7133 9.6834C29.3867 9.45007 28.9667 9.3334 28.5233 9.3334H28.5467Z'
                  fill='#78A2B9'
                />
                <path
                  d='M33.1667 39.6667H23.8333C22.55 39.6667 21.5 38.6167 21.5 37.3334C21.5 36.05 22.55 35 23.8333 35H33.1667C34.45 35 35.5 36.05 35.5 37.3334C35.5 38.6167 34.45 39.6667 33.1667 39.6667Z'
                  fill='#78A2B9'
                />
              </g>
            </g>
          </svg>
        </ActionButton>
      </div>
    </div>
  )
})

export default ErrorPage
