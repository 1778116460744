import React from 'react'
import './CustomCheckbox.css'
import { v4 as uuidv4 } from 'uuid'

const CustomCheckbox = ({ isChecked, handleCheckboxChange }) => {
  const id = uuidv4()
  return (
    <div>
      <input type='checkbox' id={id} checked={isChecked} hidden onChange={handleCheckboxChange} />
      <label htmlFor={id} className='custom-checkbox-label'>
        {isChecked && (
          <svg width='2.5vh' viewBox='0 0 32 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M10.156 19.284L29.44 0L32.0005 2.5605L10.156 24.405L-0.000488281 14.2515L2.56001 11.691L10.156 19.284Z'
              fill='white'
            />
          </svg>
        )}
      </label>
    </div>
  )
}

export default CustomCheckbox
