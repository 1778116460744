import React from 'react'

const Icon = React.forwardRef(({ iconType, styling }, ref) => {
  const getIconSrc = () => {
    switch (iconType) {
      case 'arrow':
        return `${process.env.REACT_APP_ICONS_URL}/arrow-right.svg`
      case 'camera':
        return `${process.env.REACT_APP_ICONS_URL}/camera.svg`
      case 'v':
        return `${process.env.REACT_APP_ICONS_URL}/v.svg`
      case 'faceMarker':
        return `${process.env.REACT_APP_ICONS_URL}/face-marker.svg`
      default:
        console.error(`no icon of type ${iconType} exists`)
        break
    }
  }

  return <img style={styling} src={getIconSrc()} alt='' />
})

export default Icon
