import React, { useEffect, useRef, useState } from 'react'
import './ParentalConsentPage.css'
import processText from '../../utils/processText'
import CustomCheckbox from '../Buttons/CustomCheckbox'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import { useLocation } from 'react-router-dom'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const ParentalConsentPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const [isMailingListChecked, setIsMailingListChecked] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)
  const [isParentChecked, setIsParentChecked] = useState(false)
  const [waitingForImage, setWaitingFormImage] = useState(false)
  const [imageBlob, setImageBlob] = useState(null)
  const query = useQuery()
  const [form, setForm] = useState({
    imageDocumentId: query.get('sessionId'),
    email: '',
    mobileNumber: '',
    parentFirstName: '',
    parentLastName: '',
    childFirstName: '',
    childLastName: '',
    isSubscribed: false,
    didApprove: false
  })
  const [canSubmit, setCanSubmit] = useState(false)
  const [formFields, setFormFields] = useState({
    parentFirstName: { value: '', ref: useRef(), touched: false },
    parentLastName: { value: '', ref: useRef(), touched: false },
    childFirstName: { value: '', ref: useRef(), touched: false },
    childLastName: { value: '', ref: useRef(), touched: false },
    email: { value: '', ref: useRef(), touched: false }
  })

  const handleTermsCheck = event => {
    setIsTermsChecked(event.target.checked)
    setForm(prev => ({
      ...prev,
      didApprove: event.target.checked
    }))
  }
  const share = e => {
    const file = new File([imageBlob], 'picture.jpg', { type: 'image/jpeg' })
    const filesArray = [file]

    if (navigator.share) {
      navigator
        .share({
          text: `A portrait of ${form.childFirstName}`,
          files: filesArray,
          title: `A portrait of ${form.childFirstName} - Made by Litvinovsky AI`,
          url: 'https://www.bac.org'
        })
        .then(() => console.log('Share was successful.'))
        .catch(error => console.log('Sharing failed', error))
    } else {
      downloadBlob(file)
    }
  }

  function downloadBlob (blob) {
    const url = URL.createObjectURL(blob)
    const element = document.createElement('a')
    element.setAttribute('href', url)
    element.setAttribute('download', blob.name)
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
    URL.revokeObjectURL(url) // Clean up the URL object
  }
  const handleParentCheck = event => {
    setIsParentChecked(event.target.checked)
  }

  const handleMailingListCheck = event => {
    setIsMailingListChecked(event.target.checked)
    setForm(prev => ({
      ...prev,
      isSubscribed: event.target.checked
    }))
  }
  const handleSubmit = async e => {
    e.preventDefault()
  }

  const handleBlur = field => () => {
    setFormFields(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        touched: true,
        value: prev[field].value.trim() === '' ? 'left form unfilled' : prev[field].value
      }
    }))
  }

  const setTerms = () => {
    //termsVisible ? (darkenRef.current.style.display = 'none') : (darkenRef.current.style.display = 'block')
    setTermsVisible(prev => !prev)
  }

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isMobileDevice = /android|iPad|iPhone|iPod/i.test(userAgent)
    setIsMobile(isMobileDevice)
  }, [])

  const isValidEmail = email => {
    // Trim the email to remove leading/trailing whitespace
    const cleanedEmail = email.trim()

    // Email validation pattern
    const regexPattern =
      /^(?:(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|(".+"))@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/i

    // Test the cleaned email against the regex
    const valid = regexPattern.test(cleanedEmail)

    // Set the validation state, assume setIsValid is a React state setter
    setIsValid(valid)

    return valid
  }

  useEffect(() => {
    if (
      form.email &&
      isValidEmail(form.email) &&
      form.parentFirstName &&
      form.parentLastName &&
      form.childLastName &&
      form.childFirstName &&
      isTermsChecked &&
      isParentChecked
    ) {
      setCanSubmit(true)
    } else {
      //
      setCanSubmit(false)
    }
  }, [form, isTermsChecked, isParentChecked]) // Depend on form state so this runs whenever form state changes

  const submitForm = async () => {
    try {
      setWaitingFormImage(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/session/submit-parental-approval`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ form: form })
      })

      if (!response.ok) {
        setWaitingFormImage(false)
        alert(`Image request failed`)
        throw new Error('Image request failed', response)
      }

      setWaitingFormImage(false)
      if (response.callback) {
        console.log(response.callback)
      }
      const data = await response.json()
      console.log('Submission successful!', data)

      // Assuming imageBuffer is a base64 encoded string
      if (data.imageBuffer) {
        const blob = new Blob([new Uint8Array(data.imageBuffer.data)], { type: 'image/png' })

        setImageBlob(blob)
      }

      // Handle callback data
      if (data.callback) {
        console.log('Callback data:', data.callback)
      }
    } catch (error) {
      setWaitingFormImage(false)
      console.error('Image request failed', error)
    }
  }

  const inputStyle = field => ({
    borderColor:
      formFields[field].touched && formFields[field].value === 'left form unfilled' ? '#F36666' : '#D0D9DD'
  })

  const inputTitleStyle = field => {
    return formFields[field].touched && formFields[field].value === 'left form unfilled'
      ? {
          //can I also place an svg here before the actual text?
          backgroundColor: '#F36666',
          color: 'white',
          boxSizing: 'border-box',
          gap: '0.rem',
          padding: '0 .5rem',
          direction: 'rtl'
        }
      : {
          direction: 'rtl',
          backgroundColor: 'transparent',
          color: 'var(--title-font-color)'
        }
  }

  return (
    <div className='full-page'>
      <div className='protrait-alligment' style={{ height: '100dvh', width: isMobile ? '100vw' : 'auto' }}>
        {termsVisible && (
          <TermsAndConditions
            style={{ top: '50dvh', zIndex: '101' }}
            setActive={setTerms}
          ></TermsAndConditions>
        )}
        {(termsVisible || waitingForImage) && <div className='black-overlay' style={{ zIndex: '100' }}></div>}
        <div style={{ height: '100dvh', overflow: 'auto' }}>
          <div className='headline'>
            <img className='logo-headline' src={`${process.env.REACT_APP_IMAGES_URL}/logo.png`}></img>
          </div>
          {imageBlob && (
            <div>
              <div className='parental-consent-text-container'>
                <div className='title-container'>
                  <div className='parental-consent-title'>נשלח!</div>
                </div>
                <div className='parental-consent-text'>
                  הדיוקן שלך נשלח לכתובת הדוא"ל:
                  <br />
                  <br />
                  {form.email}
                </div>
                <div className='card-parental'>
                  <img
                    className='gallery-image-parental'
                    src={URL.createObjectURL(imageBlob)}
                    alt='Fetched'
                  />
                  <div className='details-parental'>
                    <div className='card-title-parental'>הדיוקן של {form.childFirstName}</div>
                    <div className='card-text-parental'>שמן על נייר דיגיטלי, {new Date().getFullYear()}</div>
                  </div>
                </div>
                <button onClick={share} className='singular-button' style={{ marginTop: '1vh' }}>
                  שיתוף
                </button>
              </div>
            </div>
          )}
          {!imageBlob && (
            <>
              <div className='parental-consent-text-container'>
                <div className='title-container'>
                  <div className='parental-consent-title'>אישור הורה</div>
                  <div className='parental-consent-subtitle'>מחולל הדיוקנאות של בית אבי חי</div>
                </div>
                <div className='parental-consent-text'>
                  על מנת שנוכל לשמור את תמונת הדיוקן שהופקה לילד.תך באופן זמני, אנא מלא את פרטיך, קרא.י בעיון
                  ואשר.י את תנאי השימוש. <br />
                  ככל שתבחר.י לא לאשרם, הדיוקן לא ישמר במערכת ויושמד לאחר 24 שעות ממועד משלוח הודעה זו.
                </div>
              </div>
              <div className='forms-section'>
                פרטי ההורה
                <div className='forms-container'>
                  <div className='form-package'>
                    <div className='form-title' style={inputTitleStyle('parentFirstName')}>
                      {formFields['parentFirstName'].touched &&
                      formFields['parentFirstName'].value === 'left form unfilled' ? (
                        <>
                          <svg
                            style={{ overflow: 'visible' }}
                            width='0.9rem'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                              fill='white'
                            />
                            <path
                              d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                              fill='white'
                            />
                            <path
                              d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                              fill='white'
                            />
                          </svg>
                          <span>עליך להשלים את הפרטים כאן</span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <span>שם פרטי של ההורה</span>
                        </>
                      )}
                    </div>
                    <form style={{ width: '100%' }}>
                      <input
                        type='text'
                        value={form.parentFirstName}
                        ref={formFields['parentFirstName'].ref}
                        onBlur={handleBlur('parentFirstName')}
                        onChange={e => {
                          setForm(prev => ({ ...prev, parentFirstName: e.target.value }))
                          setFormFields(prev => ({
                            ...prev,
                            ['parentFirstName']: {
                              ...prev['parentFirstName'],
                              value: e.target.value,
                              touched: false
                            }
                          }))
                        }}
                        placeholder={'שם פרטי'}
                        className='input-field'
                        style={inputStyle('parentFirstName')}
                      />
                    </form>
                  </div>
                  <div className='form-package'>
                    <div className='form-title' style={inputTitleStyle('parentLastName')}>
                      {formFields['parentLastName'].touched &&
                      formFields['parentLastName'].value === 'left form unfilled' ? (
                        <>
                          <svg
                            style={{ overflow: 'visible' }}
                            width='0.9rem'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                              fill='white'
                            />
                            <path
                              d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                              fill='white'
                            />
                            <path
                              d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                              fill='white'
                            />
                          </svg>
                          <span>עליך להשלים את הפרטים כאן</span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <span>שם משפחה של ההורה</span>
                        </>
                      )}
                    </div>
                    <form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                      style={{ width: '100%' }}
                    >
                      <input
                        type='text'
                        ref={formFields['parentLastName'].ref}
                        onBlur={handleBlur('parentLastName')}
                        value={form.parentLastName}
                        onChange={e => {
                          setForm(prev => ({ ...prev, parentLastName: e.target.value }))
                          setFormFields(prev => ({
                            ...prev,
                            ['parentLastName']: {
                              ...prev['parentLastName'],
                              value: e.target.value,
                              touched: false
                            }
                          }))
                        }}
                        className='input-field'
                        placeholder='שם משפחה'
                        style={inputStyle('parentLastName')}
                      />
                    </form>
                  </div>
                  <div className='form-package'>
                    <div className='form-title' style={inputTitleStyle('email')}>
                      {formFields['email'].touched && formFields['email'].value === 'left form unfilled' ? (
                        <>
                          <svg
                            style={{ overflow: 'visible' }}
                            width='0.9rem'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                              fill='white'
                            />
                            <path
                              d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                              fill='white'
                            />
                            <path
                              d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                              fill='white'
                            />
                          </svg>
                          <span>עליך להשלים את הפרטים כאן</span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <span>דוא"ל של ההורה</span>
                        </>
                      )}
                    </div>
                    <form style={{ width: '100%' }}>
                      <input
                        type='email'
                        ref={formFields['email'].ref}
                        onBlur={handleBlur('email')}
                        value={form.email}
                        placeholder='email@gmail.com'
                        onChange={e => {
                          setForm(prev => ({ ...prev, email: e.target.value }))
                          setFormFields(prev => ({
                            ...prev,
                            ['email']: { ...prev['email'], value: e.target.value, touched: false }
                          }))
                        }}
                        className='input-field'
                      />
                    </form>
                  </div>
                  <div className='form-package'>
                    <div className='not-mandatory'>טלפון נייד</div>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                      <input
                        value={form.mobileNumber}
                        type='number'
                        placeholder='מספר טלפון'
                        onChange={e => {
                          setForm(prev => ({ ...prev, mobileNumber: e.target.value }))
                        }}
                        className='input-field'
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className='forms-section'>
                פרטי הילד
                <div className='forms-container'>
                  <div className='form-package'>
                    <div className='form-title' style={inputTitleStyle('childFirstName')}>
                      {formFields['childFirstName'].touched &&
                      formFields['childFirstName'].value === 'left form unfilled' ? (
                        <>
                          <svg
                            style={{ overflow: 'visible' }}
                            width='0.9rem'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                              fill='white'
                            />
                            <path
                              d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                              fill='white'
                            />
                            <path
                              d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                              fill='white'
                            />
                          </svg>
                          <span>עליך להשלים את הפרטים כאן</span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <span>שם פרטי של הילד</span>
                        </>
                      )}
                    </div>
                    <form style={{ width: '100%' }}>
                      <input
                        type='text'
                        value={form.childFirstName}
                        ref={formFields['childFirstName'].ref}
                        onBlur={handleBlur('childFirstName')}
                        onChange={e => {
                          setForm(prev => ({ ...prev, childFirstName: e.target.value }))
                          setFormFields(prev => ({
                            ...prev,
                            ['childFirstName']: {
                              ...prev['childFirstName'],
                              value: e.target.value,
                              touched: false
                            }
                          }))
                        }}
                        placeholder={'שם פרטי'}
                        className='input-field'
                        style={inputStyle('childFirstName')}
                      />
                    </form>
                  </div>
                  <div className='form-package'>
                    <div className='form-title' style={inputTitleStyle('childLastName')}>
                      {formFields['childLastName'].touched &&
                      formFields['childLastName'].value === 'left form unfilled' ? (
                        <>
                          <svg
                            style={{ overflow: 'visible' }}
                            width='0.9rem'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                              fill='white'
                            />
                            <path
                              d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                              fill='white'
                            />
                            <path
                              d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                              fill='white'
                            />
                          </svg>
                          <span>עליך להשלים את הפרטים כאן</span>
                        </>
                      ) : (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <span>שם משפחה של הילד</span>
                        </>
                      )}
                    </div>
                    <form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                      style={{ width: '100%' }}
                    >
                      <input
                        type='text'
                        ref={formFields['childLastName'].ref}
                        onBlur={handleBlur('childLastName')}
                        value={form.childLastName}
                        onChange={e => {
                          setForm(prev => ({ ...prev, childLastName: e.target.value }))
                          setFormFields(prev => ({
                            ...prev,
                            ['childLastName']: {
                              ...prev['childLastName'],
                              value: e.target.value,
                              touched: false
                            }
                          }))
                        }}
                        className='input-field'
                        placeholder='שם משפחה'
                        style={inputStyle('childLastName')}
                      />
                    </form>
                  </div>
                </div>
              </div>
              {/* parent */}
              <div className='checkbox-section'>
                <div className='terms-and-agreement'>
                  <div className='checkbox-parent'>
                    <CustomCheckbox
                      isChecked={isParentChecked}
                      handleCheckboxChange={handleParentCheck}
                    ></CustomCheckbox>
                  </div>
                  <div className='terms-text-container'>
                    <div className='approve-text-title'>
                      {processText(
                        'אני מצהיר/ה ומאשר/ת כי אני ההורה או האפוטרופוס החוקי של הילד',
                        'terms-link'
                      )}
                    </div>
                  </div>
                </div>

                {/* terms and conditions */}
                <div className='terms-and-agreement'>
                  <div className='checkbox-parent'>
                    <CustomCheckbox
                      isChecked={isTermsChecked}
                      handleCheckboxChange={handleTermsCheck}
                    ></CustomCheckbox>
                  </div>
                  <div className='terms-text-container'>
                    <div onClick={setTerms} className='approve-text-title'>
                      {processText(
                        'אני מאשר/ת בשמו של הילד את כל הסכמותיו במסגרת תנאי השימוש במחולל הדיוקנאות ${המופיעים כאן}',
                        'terms-link'
                      )}
                    </div>
                  </div>
                </div>

                {/* newsletter */}

                <div className='terms-and-agreement'>
                  <div className='checkbox-parent'>
                    <CustomCheckbox
                      isChecked={isMailingListChecked}
                      handleCheckboxChange={handleMailingListCheck}
                    ></CustomCheckbox>
                  </div>
                  <div className='terms-text-container'>
                    <div className='approve-text-title'>
                      {processText(
                        'אני מעוניינ.ת לקבל מידע ועדכונים לגבי מופעים, פעילויות ואירועים של בית אבי חי',
                        'terms-link'
                      )}
                    </div>
                    <div className='approve-text'>
                      {'בכל עת קיימת אפשרות להסיר את פרטיך מרשימת התפוצה לאחר קבלת הפרסומים '}
                    </div>
                  </div>
                </div>
              </div>
              <div className='b-parent'>
                <button
                  className={'singular-button'}
                  disabled={!canSubmit}
                  style={{ width: '100%', height: '8dvh' }}
                  onClick={submitForm}
                >
                  אישור
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ParentalConsentPage
