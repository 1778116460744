import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './PortraitCamera.css'
import ActionButton from '../Buttons/ActionButton'
import Icon from '../Icons/Icons'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import PhotoTimer from '../PhotoTimer/PhotoTimer'
import StageHeader from '../StageHeader/StageHeader'
import { motion } from 'framer-motion'

const PortraitCamera = React.forwardRef( ({ toggleTakingPhoto, layoutChanged }, ref) => {
  const {
    updateSession,
    takingPhoto,
    scrolling,
    currentStage,
    setStageByName,

    curSession,
    selectedLanguage
  } = useContext(SessionContext)
  const [imageCaptured, setImageCaptured] = useState(false) // State to track if an image has been captured
  const { fetchSiteData } = useContext(SiteDataContext)
  const [stageType, setStageType] = useState('takePhoto')
  const [siteData, setSiteData] = useState({})
  const [topOffset, setTopOffset] = useState(0)
  const [cameraError, setCameraError] = useState(false) // State to track camera availability errors
  const [isFeedDisplayed, setIsFeedDisplayed] = useState(false)

  const containerRef = useRef(null)
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const iconRef = useRef(null)
  const camRef = useRef(null)
  const ghostRef = useRef(null)

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage, stageType])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData(stageType)
    setSiteData(data)
  }
  const handleCameraError = error => {
    console.error('Error accessing the camera: ', error)
    setCameraError(true)
  }
  useEffect(() => {
    const calculateOffset = () => {
      const containerElement = containerRef.current
      if (containerElement) {
        const elementRect = containerElement.getBoundingClientRect()
        const scrollY = window.scrollY // Get current vertical scroll position
        const offsetFromTopOfPage = elementRect.top + scrollY
        setTopOffset(-offsetFromTopOfPage) // Calculate the negative
      }
    }

    calculateOffset()
    window.addEventListener('resize', calculateOffset) // Recalculate on window resize

    return () => window.removeEventListener('resize', calculateOffset)
  }, [takingPhoto])

  const takePhoto = () => {
    const canvas = canvasRef.current
    const video = videoRef.current

    if (canvas && video) {
      setImageCaptured(true)

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const context = canvas.getContext('2d')

      // Apply flash effect
      context.fillStyle = `rgb(255, 255, 255)`
      context.fillRect(0, 0, canvas.width, canvas.height)

      // Ensure the flash effect is visually represented for a brief moment
      setTimeout(() => {
        // Draw the video frame onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Save the canvas content
        toggleTakingPhoto()
        stopCamera()
        setStageType('approveRetake')
      }, 300) // Adjust this delay to ensure the flash effect is rendered
    }
  }

  const retakePhoto = () => {
    setImageCaptured(false)
    restartVideoStream(() => {
      startPhotoTimer()
    })
    clearCanvas()
    drawVideoFeed()
  }

  const restartVideoStream = callback => {
    const existingStream = videoRef.current ? videoRef.current.srcObject : null
    if (existingStream) {
      existingStream.getTracks().forEach(track => track.stop())
    }

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        const video = videoRef.current
        if (video) {
          video.srcObject = stream
          video.onplay = () => {
            setIsFeedDisplayed(true) // Set when the video starts playing again after restart
            if (callback) {
              callback()
            }
          }
          setCameraError(false) // Reset error state on successful connection
        }
      })
      .catch(handleCameraError)
  }

  const clearCanvas = () => {
    if (canvasRef.current && canvasRef.getContext) {
      const context = canvasRef.current.getContext('2d')
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    }
  }
  const drawVideoFeed = () => {
    if (canvasRef.current && videoRef.current && canvasRef.current.getContext) {
      const context = canvasRef.current.getContext('2d')

      // Function to draw the video stream onto the canvas
      const draw = () => {
        if (!videoRef.current.paused && !videoRef.current.ended) {
          context.save() // Save the current state of the canvas
          context.scale(-1, 1) // Flip horizontally
          context.translate(-canvasRef.current.width, 0) // Move the context to the left
          context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
          context.restore() // Restore the canvas to its original state
          requestAnimationFrame(draw) // Keep looping to draw each frame
        }
      }
      draw()
    }
  }

  const goBack = () => {
    //setStageByName('home')
    const updatedSession = { inputImageData: null }
    updateSession(updatedSession)
    setImageCaptured(false)
    setStageType('takePhoto')
    setStageByName('home')
    stopCamera()
  }

  const advanceToImageGeneration = () => {
    const canvas = canvasRef.current
    const imageData = canvas.toDataURL('image/png')
    const updatedSession = { inputImageData: imageData }
    updateSession(updatedSession)

    setImageCaptured(false)
    setStageByName('generating')
    stopCamera()
  }

  const stopCamera = () => {
    const videoElement = videoRef.current
    if (videoElement && videoElement.srcObject) {
      const tracks = videoElement.srcObject.getTracks()
      tracks.forEach(track => track.stop())
      videoElement.srcObject = null // Clear the srcObject to release the camera fully
    }
  }
  useEffect(() => {
    // Function to start the webcam feed
    if (!curSession.inputImageData) {
      setStageType('takePhoto')
    }

    const startVideo = () => {
      if (currentStage.name !== 'takePhoto') {
        return
      }

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(stream => {
            const videoCurrent = videoRef.current
            if (videoCurrent) {
              videoCurrent.srcObject = stream
              videoCurrent.onplay = () => {
                setIsFeedDisplayed(true) // Set when the video starts playing
              }
              setCameraError(false) // Reset error state on successful connection
            }
          })
          .catch(err => {
            console.error('Error accessing the webcam: ', err)
            setCameraError(true) // Reset error state on failed connection
          })
      } else {
        console.error('Current browser does not support WebRTC')
        setCameraError(true) // Reset error state on unsupported browser
      }
    }

    startVideo()

    const videoElement = videoRef.current
    // Optional: Stop the video stream on component unmount

    return () => {
      stopCamera()
    }
  }, [curSession, ghostRef, camRef])

  const startPhotoTimer = () => {
    setImageCaptured(false)
    toggleTakingPhoto()
  }

  const variants = {
    initial: {
      y: 0
    },
    active: {
      y: topOffset,
      paddingTop: '2dvh',
      width: '100%'
    }
  }
  const buttonsVariants = {
    initial: {},
    active: { opacity: 0 }
  }

  useEffect(() => {
    if (!scrolling && videoRef.current && iconRef.current) {
      const videoRect = videoRef.current.getBoundingClientRect()
      const icon = iconRef.current

      // Position icon over the video
      const iconStyle = icon.style
      iconStyle.position = 'absolute'
      // Apply the Y offset by subtracting or adding to the center alignment
      iconStyle.top = `${videoRect.height / 2 - icon.offsetHeight / 2}px`
      iconStyle.left = `${videoRect.width / 2 - icon.offsetWidth / 2}px`

      iconStyle.top = '10px'
    }
  }, [scrolling])

  return (
    <div ref={ref} className='portrait-camera-parent'>
      <StageHeader siteData={siteData} isAnimated={false}></StageHeader>
      <motion.div
        ref={containerRef}
        className='camera-container'
        variants={variants}
        initial='initial'
        animate={takingPhoto ? 'active' : 'initial'}
        transition={{ duration: 0.3, ease: 'easeOut' }} // Smooth transition on hover
      >
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className={imageCaptured ? 'videoFeed-invisible' : 'videoFeed'}
        />
        {!scrolling && !imageCaptured && (
          <Icon
            ref={iconRef}
            iconType={'faceMarker'}
            styling={{
              position: 'absolute',
              transform: 'translate(0%, 25%)',
              width: '10rem',
              zIndex: '1000'
            }}
          ></Icon>
        )}
        {imageCaptured && (
          <ActionButton buttonType={'retake'} onClick={retakePhoto}>
            {siteData.additionalText}
          </ActionButton>
        )}
        <canvas ref={canvasRef} className={imageCaptured ? 'videoFeed-preview' : 'hidden'}></canvas>
      </motion.div>

      <motion.div
        className='action-buttons-parent'
        variants={buttonsVariants}
        initial='initial'
        animate={takingPhoto ? 'active' : 'initial'}
      >
        <ActionButton
          buttonType={'standard shared-styles'}
          onClick={!imageCaptured ? startPhotoTimer : advanceToImageGeneration}
          disabled={!isFeedDisplayed}
          text={siteData.buttonRight}
        >
          {!imageCaptured && (
            <svg
              height='2.083dvh'
              width='2.2567dvh'
              viewBox='0 0 44 40'
              preserveAspectRatio='xMidYMid meet'
              style={{ overflow: 'visible' }}
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='Layer 2'>
                <path
                  id='Vector'
                  d='M36.2167 5H33.0583L31.53 2.70667C30.9754 1.87343 30.2233 1.19027 29.3408 0.718028C28.4582 0.245788 27.4726 -0.000875573 26.4717 2.33526e-06H17.8617C16.8607 -0.000875573 15.8751 0.245788 14.9926 0.718028C14.11 1.19027 13.3579 1.87343 12.8033 2.70667L11.275 5H8.11667C6.09728 5.00221 4.16123 5.80538 2.73331 7.23331C1.30538 8.66123 0.502206 10.5973 0.5 12.6167V32.3833C0.502206 34.4027 1.30538 36.3388 2.73331 37.7667C4.16123 39.1946 6.09728 39.9978 8.11667 40H36.2167C38.2361 39.9978 40.1721 39.1946 41.6 37.7667C43.028 36.3388 43.8311 34.4027 43.8333 32.3833V12.6167C43.8311 10.5973 43.028 8.66123 41.6 7.23331C40.1721 5.80538 38.2361 5.00221 36.2167 5ZM40.5 32.3833C40.4987 33.5189 40.047 34.6077 39.244 35.4106C38.441 36.2136 37.3523 36.6653 36.2167 36.6667H8.11667C6.98106 36.6653 5.89235 36.2136 5.08935 35.4106C4.28636 34.6077 3.83466 33.5189 3.83333 32.3833V12.6167C3.83466 11.4811 4.28636 10.3923 5.08935 9.58936C5.89235 8.78636 6.98106 8.33466 8.11667 8.33333H12.1667C12.441 8.33338 12.7111 8.26571 12.953 8.13633C13.1949 8.00695 13.4011 7.81986 13.5533 7.59167L15.5767 4.555C15.8274 4.17887 16.1672 3.87053 16.5658 3.6574C16.9645 3.44427 17.4096 3.33295 17.8617 3.33334H26.4717C26.9237 3.33295 27.3688 3.44427 27.7675 3.6574C28.1661 3.87053 28.5059 4.17887 28.7567 4.555L30.78 7.59167C30.9322 7.81986 31.1385 8.00695 31.3804 8.13633C31.6223 8.26571 31.8923 8.33338 32.1667 8.33333H36.2167C37.3523 8.33466 38.441 8.78636 39.244 9.58936C40.047 10.3923 40.4987 11.4811 40.5 12.6167V32.3833Z'
                  fill='white'
                />
                <path
                  id='Vector_2'
                  d='M22.1668 11.6666C20.1889 11.6666 18.2555 12.2531 16.611 13.3519C14.9666 14.4507 13.6848 16.0125 12.928 17.8398C12.1711 19.6671 11.973 21.6777 12.3589 23.6175C12.7448 25.5573 13.6972 27.3392 15.0957 28.7377C16.4942 30.1362 18.276 31.0886 20.2158 31.4745C22.1557 31.8603 24.1663 31.6623 25.9936 30.9054C27.8208 30.1485 29.3826 28.8668 30.4814 27.2223C31.5803 25.5778 32.1668 23.6444 32.1668 21.6666C32.1641 19.0153 31.1097 16.4733 29.2349 14.5985C27.3601 12.7237 24.8181 11.6693 22.1668 11.6666ZM22.1668 28.3333C20.8482 28.3333 19.5593 27.9423 18.463 27.2098C17.3666 26.4772 16.5121 25.436 16.0076 24.2178C15.503 22.9997 15.3709 21.6592 15.6282 20.366C15.8854 19.0728 16.5204 17.8849 17.4527 16.9526C18.3851 16.0202 19.5729 15.3853 20.8662 15.1281C22.1594 14.8708 23.4998 15.0028 24.718 15.5074C25.9362 16.012 26.9773 16.8665 27.7099 17.9628C28.4424 19.0592 28.8334 20.3481 28.8334 21.6666C28.8334 23.4347 28.131 25.1304 26.8808 26.3807C25.6306 27.6309 23.9349 28.3333 22.1668 28.3333Z'
                  fill='white'
                />
              </g>
            </svg>
          )}
        </ActionButton>
        <ActionButton
          buttonType={'inverted shared-styles'}
          onClick={goBack}
          text={siteData.buttonLeft}
        ></ActionButton>
      </motion.div>
      {takingPhoto && <PhotoTimer time={3} callback={takePhoto}></PhotoTimer>}
    </div>
  )
})

export default PortraitCamera
