import React, { useContext, useEffect, useRef, useState } from 'react'
import './Shred.css'
import ActionButton from '../Buttons/ActionButton'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import { useSocket } from '../../contexts/SocketContext'

const Shred = React.forwardRef(({}, ref) => {
  const { setStageByName, selectedLanguage, deleteSession } = useContext(SessionContext)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({})
  const [shreddedData, setShreddedData] = useState({})
  const [editedText, setEditedText] = useState({ title: '', text: '' })
  const socket = useSocket()
  const siteDataRef = useRef({})

  const updatedDB = useRef(false)

  useEffect(() => {
    handleSiteData()

    return () => {
      //socket.off('portraits_sum_updated')
    }
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('shred')
    setSiteData(data)
    siteDataRef.current = data
    //update the portrait's sum
    if (!updatedDB.current) {
      updatedDB.current = true
      updatePortraitSum()
    }
  }

  const editTextAndTitle = shredData => {
    let siteDataText = siteDataRef.current.text

    // Function to format numbers with commas
    const formatNumber = num => new Intl.NumberFormat().format(num)

    // Replace placeholders with the corresponding values from shredData
    siteDataText = siteDataText
      .replace('createdAmount', formatNumber(shredData.created))
      .replace('savedAmount', formatNumber(shredData.saved))

      const title = siteDataRef.current.title

    // Call setEditedText with the updated text
    setEditedText({ title: title, text: siteDataText })
  }

  const updatePortraitSum = async () => {
    socket.emit('image_status', { saved: false })
    socket.on('portraits_sum_updated', shredData => {
      editTextAndTitle(shredData)
    })
  }

  const goHome = () => {
    setStageByName('home')
    deleteSession()
  }

  return (
    <div ref={ref}>
      <StageHeader siteData={editedText}></StageHeader>
      <div className='horizontaly-center'>
        <img className='shred-gif' src={`${process.env.REACT_APP_IMAGES_URL}/shred.gif`} />
        <div className='singular-button' style={{ width: '80%' }} onClick={goHome}>
          {siteData.buttonRight}
        </div>
      </div>
    </div>
  )
})

export default Shred
