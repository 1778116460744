import React, { useEffect, useState } from 'react'
import './Login.css'

const LoginComponent = ({ handleLogin, handleLogout, role }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const attemptAuthentication = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/check?role=${role}`, {
      method: 'GET',
      credentials: 'include' // Include credentials (cookies) in the request
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          handleLogin(data.token) // Update the authentication state in AuthContext
        })
      }else{
        console.log('User has an invalid token')
        handleLogout()
      }
    })
  }

  useEffect(() => {
    attemptAuthentication()
  }, [])

  const onLogin = async () => {
    try {
      console.log("Attempting login...")
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include' // Include credentials (cookies) in the request
      })

      if (!response.ok) {
        throw new Error('Login failed')
      }

      const data = await response.json()
      //handleLogin(data.token) // Pass the token to the parent component
      attemptAuthentication()
    } catch (error) {
      console.error('Login failed', error)
    }
  }

  return (
    <div className='login-parent'>
      <input
        type='text'
        placeholder='Username'
        value={username}
        onChange={e => setUsername(e.target.value)}
        className='input-field'
      />
      <input
        type='password'
        placeholder='Password'
        value={password}
        className='input-field'
        onChange={e => setPassword(e.target.value)}
      />
      <button className='login-buttons' onClick={onLogin}>
        Login
      </button>
    </div>
  )
}

export default LoginComponent
