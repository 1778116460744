import React, { createContext, useState, useEffect } from 'react'
import Cookies from 'js-cookie'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false)
  const [token, setToken] = useState(null)
  const [adminToken, setAdminToken] = useState(null)

  const login = (token, isAdmin = false) => {
    if (isAdmin) {
      setAdminToken(token)
      setIsAdminAuthenticated(true)
      Cookies.set('adminToken', token) // Save the token to cookies on login
    } else {
      setToken(token)
      setIsAuthenticated(true)
      Cookies.set('token', token) // Save the token to cookies on login
    }
  }

  const logout = () => {
    setToken(null)
    setIsAuthenticated(false)
    Cookies.remove('token') // Remove the token from cookies on logout
  }

  useEffect(() => {
    const storedToken = Cookies.get('token')
    if (storedToken) {
      setToken(storedToken)
      setIsAuthenticated(true)
    }
    const storedAdminToken = Cookies.get('adminToken')
    if (storedAdminToken) {
      console.log('admin authenticated')
      setAdminToken(storedAdminToken)
      setIsAuthenticated(true)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAdminAuthenticated,
        login,
        logout,
        token,
        adminToken
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
