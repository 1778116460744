import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PortraitCamera from '../PortraitCamera/PortraitCamera'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import OutputGallery from '../OutputGallery/OutputGallery'
import LoadingPage from '../LoadingPage/LoadingPage'
import NavigationToolbar from '../Buttons/NavigationToolbar'
import './SessionManager.css'
import HomePage from '../HomePage/HomePage'
import InputName from '../InputName/InputName'
import Selection from '../Selection/Selection'
import InputEmail from '../InputEmail/InputEmail'
import Shred from '../Shred/Shred'
import EmailSending from '../EmailSending/EmailSending'
import EmailSuccess from '../EmailSuccess/EmailSuccess'
import ErrorPage from '../ErrorPage/ErrorPage'
import { InactivityTimer } from '../Utils/InactivityTimer'
import { AuthContext } from '../../contexts/AuthContext'
import LoginComponent from '../Authentication/Login'
import { SocketProvider } from '../../contexts/SocketContext'
import AgePage from '../AgePage/AgePage'
import Passcode from '../Passcode/Passcode'
import PasscodeSend from '../passcodeSend/PasscodeSend'

export const SessionManager = () => {
  const { isAuthenticated, login, logout } = useContext(AuthContext)

  const [currentStage, setCurrentStage] = useState({})
  const [siteDatas, setSiteDatas] = useState([])

  const [curSession, setSession] = useState({})
  const [layoutChanged, setLayoutChanged] = useState(false)
  const [takingPhoto, setTakingPhoto] = useState(false)
  const [scrolling, setScrolling] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [isMobile, setIsMobile] = useState(false)

  const scrollerRef = useRef(null)
  const [activeStages, setActiveStages] = useState([])

  const [token, setToken] = useState(null)

  const stageRendered = useRef(false)
  const scrollEndTimer = useRef(null)
  const darkenRef = useRef(null)
  const appContainerRef = useRef(null)

  const [isChromium, setIsChromium] = useState(false)
  const [shouldScrollAgain, setShouldScrollAgain] = useState(false)

  const stages = [
    { name: 'home', index: 0 },
    { name: 'inputName', index: 1 },
    { name: 'takePhoto', index: 2 },
    { name: 'approveRetake', index: 3 },
    { name: 'generating', index: 4 },
    { name: 'resultsGallery', index: 5 },
    { name: 'selection', index: 6 },
    { name: 'inputEmail', index: 7 },
    { name: 'shred', index: 8 },
    { name: 'emailSending', index: 9 },
    { name: 'emailSuccess', index: 10 },
    { name: 'errorPage', index: 11 },
    { name: 'agePage', index: 12 },
    { name: 'passcode', index: 13 },
    { name: 'passcodeSend', index: 14 }
  ]

  const stageRefs = useRef({
    home: React.createRef(),
    takePhoto: React.createRef(),
    generating: React.createRef(),
    resultsGallery: React.createRef(),
    selection: React.createRef(),
    inputEmail: React.createRef(),
    shred: React.createRef(),
    emailSending: React.createRef(),
    emailSuccess: React.createRef(),
    errorPage: React.createRef(),
    agePage: React.createRef(),
    passcode: React.createRef(),
    passcodeSend: React.createRef()
  })

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isMobileDevice = /android|iPad|iPhone|iPod/i.test(userAgent)
    setIsMobile(isMobileDevice)
  }, [])

  const handleLogin = token => {
    setToken(token)
    login(token, false) // Update the authentication state in AuthContext
  }

  const handleLogout = () => {
    setToken(null)
    logout()
  }

  const selectLanguage = language => {
    setSelectedLanguage(language)
  }

  const deleteSession = () => {
    setSession({})
  }

  const updateSession = newData => {
    setSession(prevSession => ({ ...prevSession, ...newData }))
  }

  const toggleTakingPhoto = () => {
    setTakingPhoto(prev => !prev)
  }

  const deleteStages = stagesToRemove => {
    // Filter activeStages to exclude stagesToRemove
    const filteredStages = activeStages.filter(stage => !stagesToRemove.includes(stage.name))

    // Update the activeStages with the filtered list
    setActiveStages(filteredStages)
  }

  const setStageByName = name => {
    if (scrolling) {
      //console.log("currently scrolling")
      return
    }

    const stage = stages.find(stage => stage.name === name)
    if (stage) {
      if (name === 'home') {
        // Use activeStage to see what is the current active stage
        // Clear all stages from the activeStages array, except the current one and home
        const updatedStages = activeStages.filter(stage => stage === currentStage || stage.name === 'home')

        setActiveStages(updatedStages)

        // setTimeout(() => {
        // }, 100)
        changeStage(stage)
      } else {
        changeStage(stage)
      }
    } else {
      console.error(`Stage ${name} doesn't exist`)
    }
  }

  const changeStage = newStage => {
    if (!activeStages.some(stage => stage.name === newStage.name)) {
      setActiveStages(prev => [...prev, newStage])
    }
    if (newStage.name === 'generating') {
      setShouldScrollAgain(true)
    }
    setCurrentStage(newStage)
  }

  const fetchSiteData = async stageName => {
    //first check if a cached version of the data exist
    let data

    if (!Array.isArray(siteDatas)) {
      console.error('siteDatas is not an array or not loaded yet')
      return // or handle this case appropriately, perhaps by initializing siteDatas or retrying data fetching
    }
    let cachedData = null

    try {
      cachedData = siteDatas.find(data => data.stage === stageName && data.language === selectedLanguage)
    } catch (e) {
      console.error(e)
    }

    if (cachedData) {
      //setSiteDatas(prevSiteData => [...prevSiteData, cachedData])
      data = cachedData
    } else {
      try {
        const baseUrl = process.env.REACT_APP_API_URL

        const url = `${baseUrl}/api/site-data?stage=${encodeURIComponent(
          stageName
        )}&language=${encodeURIComponent(selectedLanguage)}`

        const response = await fetch(url)

        if (!response.ok) {
          const errorBody = await response.text()
          console.error(`Server responded with ${response.status}: ${errorBody}`)
        }

        data = await response.json()
      } catch (error) {
        console.error('An error occured trying to fetch site data for current stage. ', error.message)
        return { title: '', text: '', buttonRight: '', buttonLeft: '', additionalText: '' }
      }
      setSiteDatas(prevSiteData => [...prevSiteData, data])
    }
    if (data === null) {
      console.error('No data was found for the requested stage')
    }
    if (data) {
      return data
    } else {
      console.error(`No data found, cached or on the DB, for the following stage: ${stageName}`)
      return {
        title: '',
        text: '',
        additionalText: '',
        buttonLeft: ''
      }
    }
  }

  useEffect(() => {
    if (!stageRendered.current) {
      stageRendered.current = true
      changeStage(stages[0])
    }
  }, [])

  useEffect(() => {
    if (stages.some(stage => stage.index === currentStage.index)) {
      const s = stages.find(stage => stage.index === currentStage.index)
      fetchSiteData(s.name)
    }
  }, [currentStage.index, selectLanguage])

  const renderStage = stageIndex => {
    switch (stageIndex) {
      case 0:
        return <HomePage ref={stageRefs.current.home} key='home' selectLanguage={selectLanguage}></HomePage>
      case 1:
      case 2:
      case 3:
        return (
          <PortraitCamera
            ref={stageRefs.current.takePhoto}
            toggleTakingPhoto={toggleTakingPhoto}
            layoutChanged={layoutChanged}
          ></PortraitCamera>
        )
      case 4:
        return <LoadingPage ref={stageRefs.current.generating}></LoadingPage>
      case 5:
        return <OutputGallery  ref={stageRefs.current.resultsGallery} ></OutputGallery>
      case 6:
        return <Selection id='selection' ref={stageRefs.current.selection}></Selection>
      case 7:
        return <InputEmail id='inputEmail' ref={stageRefs.current.inputEmail}></InputEmail>
      case 8:
        return <Shred ref={stageRefs.current.shred}></Shred>
      case 9:
        return <EmailSending id='emailSending' ref={stageRefs.current.emailSending}></EmailSending>
      case 10:
        return <EmailSuccess id='emailSuccess' ref={stageRefs.current.emailSuccess}></EmailSuccess>
      case 11:
        return <ErrorPage id='errorPage' ref={stageRefs.current.errorPage}></ErrorPage>
      case 12:
        return <AgePage id='agePage' ref={stageRefs.current.agePage}></AgePage>
      case 13:
        return <Passcode id='passcode' ref={stageRefs.current.passcode}></Passcode>
      case 14:
        return <PasscodeSend id='passcodeSend' ref={stageRefs.current.passcodeSend}></PasscodeSend>
      default:
        return <HomePage selectLanguage={selectLanguage} ref={stageRefs.current.home}></HomePage>
    }
  }
  useEffect(() => {
    const checkChromium = () => {
      const userAgent = navigator.userAgent
      const chromiumBrowsers = [
        'Chrome',
        'Chromium',
        'CriOS',
        'HeadlessChrome',
        'Edg',
        'Brave',
        'Opera',
        'OPR',
        'Vivaldi',
        'SamsungBrowser'
      ]
      return chromiumBrowsers.some(browser => userAgent.includes(browser))
    }

    setIsChromium(checkChromium())
  }, [])

  const attemptScroll = useCallback(() => {
    if (!scrollerRef) return
    if (!scrollerRef.current) return

    //const stageElement = document.getElementById(currentStage?.name)
    //console.log("Current stage refs", stageRefs)
    const stageElement = stageRefs.current[currentStage?.name].current

    if (!stageElement) {
      console.error(`Stage ${currentStage?.name} was not found in the DOM`)
      return
    }
    if (!scrolling) {
      setScrolling(true)
      stageElement.focus()
      stageElement.scrollIntoView({
        behavior: 'instant',
        block: 'start',
        inline: 'start'
      })
    }

    //might need to change this to only happen on chromium
    //and only if the current stage is resultsGallery
    if (isChromium && currentStage.name === 'resultsGallery' && shouldScrollAgain) {
      setTimeout(() => {
        if (!scrolling) {
          setScrolling(true)
          stageElement.focus()
          stageElement.scrollIntoView({
            behavior: 'instant',
            block: 'start',
            inline: 'start'
          })
        }
        setShouldScrollAgain(false)
      }, 100)
    }

    const checkScrollCompletion = callback => {
      clearTimeout(scrollEndTimer.current)
      const lastScrollLeft = scrollerRef.current.scrollLeft

      scrollEndTimer.current = setTimeout(() => {
        const currentScrollLeft = scrollerRef.current.scrollLeft
        if (currentScrollLeft === lastScrollLeft) {
          callback()
        } else {
          checkScrollCompletion(callback)
        }
      }, 100)
    }

    const onScrollComplete = () => {
      setScrolling(false)
      if (currentStage?.name === 'home' && activeStages.some(stage => stage.name !== 'home')) {
        clearStages()
      }
    }
    checkScrollCompletion(onScrollComplete)
  })

  useLayoutEffect(() => {
    attemptScroll()
    return () => {
      clearTimeout(scrollEndTimer.current)
    }
  }, [currentStage])

  const clearStages = useCallback(() => {
    const stagesToKeep = ['home']

    // Filter activeStages to only include 'home' and currentStage
    const filteredStages = activeStages.filter(stage => stagesToKeep.includes(stage.name))

    // Update the activeStages with the filtered list
    setActiveStages(filteredStages)
  })

  const renderStageContent = stage => {
    return <div className='horizontaly-center'>{renderStage(stage.index)}</div>
  }

  return (
    <>
      {isAuthenticated ? (
        <div className='app-container'>
          <SocketProvider token={token}>
            <div className='darken' ref={darkenRef} style={{ display: 'none' }}></div>
            <div
              className='protrait-alligment'
              ref={appContainerRef}
              style={{ width: isMobile ? '100vw' : 'auto' }}
            >
              <SiteDataContext.Provider value={{ siteDatas, fetchSiteData }}>
                <SessionContext.Provider
                  value={{
                    scrolling,
                    deleteStages,
                    curSession,
                    takingPhoto,
                    updateSession,
                    selectedLanguage,
                    currentStage,
                    setStageByName,
                    deleteSession,
                    darkenRef
                  }}
                >
                  <InactivityTimer
                    timeout={process.env.REACT_APP_INACTIVITY_TIME || 60}
                    onTimeout={() => {
                      setStageByName('home')
                      deleteSession()
                    }}
                  />
                  <NavigationToolbar></NavigationToolbar>
                  <div
                    className='stages-scroller snaps-inline'
                    style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}
                    ref={scrollerRef}
                  >
                    {activeStages.map((stage, index) => (
                      <div id={stage.name} key={index}>
                        {renderStageContent(stage)}
                      </div>
                    ))}
                  </div>
                </SessionContext.Provider>
              </SiteDataContext.Provider>
            </div>
          </SocketProvider>
        </div>
      ) : (
        <LoginComponent handleLogin={handleLogin} handleLogout={handleLogout} role={'user'}></LoginComponent>
      )}
    </>
  )
}

//session data schema:
// {
//   id:
//   dateTime:
//   name:
//   email:
//   inputImageData: which is temp
//   outputImagesData: which is temp
//   selectedImageData:
//   selectedImageData/selectedImageUrl:  //depending on packedSession or currentSession
// }
