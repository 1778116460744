import './App.css'
import AdminPage from './components/AdminPage/AdminPage'
import ParentalConsentPage from './components/ParentalConsentPage/ParentalConsentPage'
import { SessionManager } from './components/SessionManager/SessionManager'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom'

function App () {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/adminpage' Component={AdminContent}></Route>
          <Route path='/approve' Component={ParentalConsentPage}></Route>
          <Route path='/' Component={MainPage}></Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App

function AdminContent () {
  return (
    <AuthProvider>
      <AdminPage></AdminPage>
    </AuthProvider>
  )
}

function MainPage () {
  return (
    <AuthProvider>
      <SessionManager></SessionManager>
    </AuthProvider>
  )
}
