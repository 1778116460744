import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import './PhotoTimer.css'

const PhotoTimer = ({ time, callback }) => {
  const [timeLeft, setTimeLeft] = useState(time)

  const [mounted, setMounted] = useState(false)
  const nodeRef = useRef(null)
  useEffect(() => {
    setMounted(true)
    // If timeLeft is 0, stop the countdown
    if (timeLeft === 0) {
      if (callback) {
        callback()
      }
      return
    }

    // Set up a timer to decrement the timeLeft by 1 every second
    const timerId = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // Clean up the timer if the component is unmounted or the timeLeft changes
    return () => clearTimeout(timerId)
  }, [timeLeft]) // Depend on timeLeft to re-run the effect when it changes

  return (
    <CSSTransition nodeRef={nodeRef} in={mounted} timeout={300} classNames={'fade'} unmountOnExit>
      <div ref={nodeRef}>
        <div className='timer-parent'>
          <div className='photo-timer'>
            <div style={{ fontSize: '3.6dvh' }}>{timeLeft}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default PhotoTimer
