import React, { useContext, useEffect, useState } from 'react'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import ActionButton from '../Buttons/ActionButton'
import './AgePage.css'
const AgePage = React.forwardRef(({}, ref) => {
  const { selectedLanguage, setStageByName, updateSession } = useContext(SessionContext)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({
    title: '',
    text: '',
    additionalText: '',
    buttonLeft: ''
  })

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('agePage')
    setSiteData(data)
  }

  return (
    <div ref={ref}>
      <StageHeader siteData={siteData}></StageHeader>
      <div className='buttons-container'>
        <ActionButton
          onClick={() => {
            setStageByName('inputEmail')
          }}
          buttonType={'standard shared-styles'}
          style={{ width: '100%' }}
        >
          {siteData.buttonRight}
        </ActionButton>
        <ActionButton
          style={{ width: '100%' }}
          onClick={() => {
            updateSession({ isUnderage: true })
            setStageByName('passcode')
          }}
          buttonType={'inverted shared-styles'}
        >
          {siteData.buttonLeft}
        </ActionButton>
      </div>
    </div>
  )
})

export default AgePage
