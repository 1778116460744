import React, { useContext, useEffect, useRef, useState } from 'react'
import './Passcode.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import ActionButton from '../Buttons/ActionButton'

const Passcode = React.forwardRef(({}, ref) => {
  const { fetchSiteData } = useContext(SiteDataContext)
  const { selectedLanguage, setStageByName, updateSession } = useContext(SessionContext)
  const [validPasscode, setValidPasscode] = useState(false)
  const passcodeAmount = 4
  const [passcode, setPasscode] = useState(Array(passcodeAmount).fill(''))
  const [focused, setFocused] = useState(new Array(4).fill(false))
  const inputRefs = useRef([])
  const [siteData, setSiteData] = useState({
    title: '',
    text: '',
    additionalText: '',
    buttonLeft: ''
  })
  useEffect(() => {
    if (passcode) {
      if (passcode.every(value => value !== '' || validPasscode)) {
        checkPasscode(passcode.join('')) // Print passcode as a single string
      }
    }
  }, [passcode])

  const checkPasscode = async passcode => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/passcode?passcode=${passcode}`)
      if (response.status === 200) {
        setValidPasscode(true)
        updateSession({ passcode: passcode })
      } else {
        setValidPasscode(false)
      }
    } catch (error) {
      console.error('Error checking passcode:', error)
      setValidPasscode(false)
    }
  }

  const handleChange = (index, value) => {
    if (value.length > 1) return // Ensure only a single character is entered

    const newPasscode = [...passcode]
    if (!value) {
      newPasscode[index] = ''
    } else {
      newPasscode[index] = value
    }
    setPasscode(newPasscode)

    if (value && index < passcodeAmount - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('passcode')
    setSiteData(data)
  }
  const blocks = Array.from({ length: passcodeAmount }, (_, index) => (
    <input
      key={index}
      type='text'
      inputMode='numeric'
      pattern='[0-9]*'
      className='passcode-block'
      value={passcode[index]}
      onChange={e => handleChange(index, e.target.value)}
      ref={el => (inputRefs.current[index] = el)}
      maxLength='1'
      placeholder={focused[index] ? '' : '*'}
      onFocus={() => {
        const newFocused = [...focused]
        newFocused[index] = true
        setFocused(newFocused)
      }}
      onBlur={() => {
        const newFocused = [...focused]
        newFocused[index] = false
        setFocused(newFocused)
      }}
    />
  ))
  return (
    <div ref={ref} className='passcode-container'>
      <StageHeader siteData={siteData} renderOnly={'title'}></StageHeader>

      <ActionButton
        onClick={() => {
          updateSession({ passcode: null })
          setPasscode('')
          setStageByName('passcodeSend')
        }}
        style={{ width: '100%', minHeight: '8dvh' }}
        buttonType={'standard shared-styles'}
      >
        {siteData.buttonRight}
      </ActionButton>

      <div className='horizontal-line'></div>
      <StageHeader siteData={siteData} renderOnly={'text'}></StageHeader>
      <div className='passcode-blocks-parent'>{blocks}</div>
      <ActionButton
        style={{ width: '100%', minHeight: '8dvh' }}
        disabled={!validPasscode}
        buttonType={'inverted shared-styles'}
        onClick={() => {
          setStageByName('passcodeSend')
        }}
      >
        {siteData.buttonLeft}
      </ActionButton>
    </div>
  )
})

export default Passcode
