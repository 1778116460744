import React, { useContext, useEffect, useRef, useState } from 'react'
import './StageHeader.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'

const StageHeader = ({ siteData, isAnimated, renderOnly = '' }) => {
  const ghostRef = useRef(null)
  const headerRef = useRef(null)
  const titleRef = useRef(null)
  const textRef = useRef(null)
  const { takingPhoto, currentStage, selectedLanguage } = useContext(SessionContext)

  const updateGhostHeight = () => {
    // Ensure refs are current before attempting to read dimensions.
    if (titleRef.current && textRef.current) {
      const titleHeight = titleRef.current.getBoundingClientRect().height
      const textHeight = textRef.current.getBoundingClientRect().height
      const totalHeight = titleHeight + textHeight
      ghostRef.current.style.height = `${totalHeight}px`
    }
  }

  useEffect(() => {
    if (isAnimated) {
      updateGhostHeight()
    }
  }, [titleRef.current, textRef.current, siteData])

  const updateStyling = () => {
    const h = headerRef.current
    h.style.top = '2%'
  }
  const positionHeader = () => {
    if (ghostRef.current && headerRef.current) {
      const { top, left, width } = ghostRef.current.getBoundingClientRect()
      const header = headerRef.current
      header.style.position = 'fixed'
      header.style.top = `${top}px`
      header.style.left = `${left}px`
      header.style.width = `${width}px` // Assuming you want it full width.
    }
  }

  // Update ghost size and position header when dependencies change.
  useEffect(() => {
    if (!isAnimated) return

    updateGhostHeight()
    positionHeader()
    // Potentially re-position when the window resizes, as the ghost's position might change.
    window.addEventListener('resize', positionHeader)
    if (takingPhoto === true) {
      updateStyling()
    }

    //onLayoutChanged()

    return () => {
      window.removeEventListener('resize', positionHeader)
    }
  }, [takingPhoto, currentStage])

  return (
    <div ref={ghostRef} className='header-ghost'>
      <div
        ref={headerRef}
        className='header-container'
        style={{ direction: selectedLanguage === 'he' ? 'rtl' : 'ltr' }}
      >
        {(renderOnly === 'title' || renderOnly === '') && (
          <div ref={titleRef} className='title'>
            {siteData?.title || ''}
          </div>
        )}
        {(renderOnly === 'text' || renderOnly === '') && (
          <div ref={textRef} className='text'>
            {siteData?.text || ''}
          </div>
        )}
      </div>
    </div>
  )
}

export default StageHeader

// if (curStageIndex === 6) {
//   let titleText = curSession.name
//     ? data.title.concat(' ', curSession.name)
//     : selectedLanguage === 'en'
//     ? data.title.concat(' ', 'you')
//     : data.title.concat('ך')

//   setTitle(titleText)

//   let textText = data.text.concat(' ', new Date().getFullYear())
//   setText(textText)
