import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import './HomePage.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'

const HomePage = React.forwardRef(({ selectLanguage }, ref) => {
  const { selectedLanguage, setStageByName, darkenRef, currentStage } = useContext(SessionContext)
  const [isChecked, setIsChecked] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)

  let refreshTime = 600

  const [timeLeft, setTimeLeft] = useState(refreshTime)

  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({
    title: '',
    text: '',
    additionalText: '',
    buttonLeft: ''
  })

  const resetTimer = useCallback(() => {
    setTimeLeft(refreshTime)
  }, [])

  useEffect(() => {
    let timer

    const handleUserActivity = () => {
      if (currentStage.name === 'home') {
        resetTimer()
      }
    }

    if (currentStage.name === 'home') {
      // Start the countdown if the current stage is 'home'
      timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer)
            window.location.reload() // Refresh the page when the timer reaches 0
            return 0
          }
          return prevTime - 1
        })
      }, 1000)

      // Add event listeners for user activity
      window.addEventListener('mousemove', handleUserActivity)
      window.addEventListener('keydown', handleUserActivity)
    } else {
      // Reset the timer if the current stage is not 'home'
      setTimeLeft(refreshTime)
    }

    // Cleanup function to clear the interval and event listeners
    return () => {
      clearInterval(timer)
      window.removeEventListener('mousemove', handleUserActivity)
      window.removeEventListener('keydown', handleUserActivity)
    }
  }, [currentStage, resetTimer])

  const setTerms = () => {
    termsVisible ? (darkenRef.current.style.display = 'none') : (darkenRef.current.style.display = 'block')
    setTermsVisible(prev => !prev)
  }

  useEffect(() => {
    if (currentStage.name === 'home') {
      setIsChecked(false)
    }
  }, [currentStage])

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    const data = await fetchSiteData('home')
    setSiteData(data)
  }

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked)
  }

  return (
    <div ref={ref} className='homepage-container'>
      <div className='homepage-header-container'>
        <img className='logo' src={`${process.env.REACT_APP_ICONS_URL}/logo_en.svg`}></img>
        <div className='language-selection-container'>
          <div
            className={`language-select  ${selectedLanguage === 'en' ? 'selected' : ''} `}
            onClick={() => selectLanguage('en')}
          >
            en
          </div>
          <div className={`language-select semi-transparent  selected`}>/</div>
          <div
            className={`language-select  ${selectedLanguage === 'he' ? 'selected' : ''} `}
            onClick={() => selectLanguage('he')}
          >
            he
          </div>
        </div>
      </div>
      <div className='front-image-container'>
        <img className='front-image' src={`${process.env.REACT_APP_IMAGES_URL}/homepage-portrait.png`}></img>
      </div>
      <div className='homepage-text-container'>
        <div className='homepage-title'>{siteData.title ? siteData.title + ' ' + siteData.text : ''}</div>
        <div className='homepage-text'>{siteData.additionalText ? siteData.additionalText : ''}</div>
      </div>
      <button className='singular-button' onClick={() => setStageByName('takePhoto')}>
        <div className='start-button-text'>{siteData.buttonLeft ? siteData.buttonLeft : ''}</div>
        <div className='arrow-container'>
          <svg
            style={selectedLanguage === 'he' ? { transform: 'scaleX(-1)' } : { transform: 'scaleX(1)' }}
            height='1.6vh'
            viewBox='0 0 24 37'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g id='layer1'>
              <path
                id='path9429'
                d='M2.91093 0.800672C2.37595 0.788906 1.8506 0.94399 1.40791 1.2446C0.965214 1.54521 0.627183 1.9765 0.440811 2.4781C0.254438 2.97971 0.228985 3.52685 0.367988 4.04359C0.506991 4.56033 0.803543 5.02071 1.21642 5.36112L16.9699 18.8569L1.21642 32.3479C0.930812 32.5578 0.691624 32.8243 0.513833 33.1309C0.336042 33.4376 0.223479 33.7777 0.183191 34.1299C0.142902 34.482 0.175758 34.8386 0.279699 35.1775C0.383641 35.5163 0.556429 35.8302 0.787244 36.0992C1.01806 36.3682 1.30193 36.5864 1.62107 36.7406C1.94022 36.8948 2.28777 36.9814 2.64195 36.9951C2.99614 37.0087 3.34933 36.9493 3.6794 36.8202C4.00948 36.691 4.30934 36.4951 4.5602 36.2447L22.5965 20.8129C22.8797 20.5711 23.1072 20.2711 23.2631 19.933C23.4191 19.5949 23.4998 19.2269 23.4998 18.8545C23.4998 18.4822 23.4191 18.1141 23.2631 17.776C23.1072 17.4379 22.8797 17.1376 22.5965 16.8959L4.5602 1.4492C4.10457 1.0445 3.52017 0.814416 2.91093 0.800078V0.800672Z'
                fill='white'
              />
            </g>
          </svg>
        </div>
      </button>
    </div>
  )
})
export default HomePage
