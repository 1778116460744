import React, { useContext, useEffect, useState } from 'react'
import './ActionButton.css'
import { SessionContext } from '../../contexts/SessionContext'

const ActionButton = ({ buttonType, onClick, text, disabled = false, children, style }) => {
  const { selectedLanguage } = useContext(SessionContext)
  const [direction, setDirection] = useState('ltr')
  useEffect(() => {
    if (selectedLanguage === 'he') {
      setDirection('ltr')
    } else {
      setDirection('rtl')
    }
  }, [selectedLanguage])
  return (
    <button disabled={disabled} style={style} className={`${buttonType}`} onClick={onClick}>
      <div className='button-content' style={{ direction: direction }}>
        {children}
        {text}
      </div>
    </button>
  )
}
export default ActionButton
