import { motion } from 'framer-motion'

const Line = ({ isFilled, isRtl = false }) => {
  // Control the animation start and end points based on RTL and whether the line is filled
  const animateFrom = { width: '0%' }
  const animateTo = isFilled ? { width: '100%' } : { width: '0%' }

  return (
    <div
      className='line dotted'
      style={{
        position: 'relative',
        color: 'rgba(231, 241, 246, 1)',
        height: '0.2rem',
        width: '100%'
      }}
    >
      {/* Dotted line, always visible */}
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: 0,
          color: '#a1aebe',
          right: 0,
          height: '0.2rem',
          width: '100%',
          zIndex: 1 // Ensure this is below the filled line
        }}
      />

      {/* Filled line, conditionally animates */}
      <motion.div
        initial={{ width: '0%' }}
        animate={animateTo}
        transition={{ duration: 1, ease: 'easeOut' }}
        style={{
          position: 'absolute',
          top: '-0.25vh',
          left: isRtl ? '100%' : '0', // Adjust starting position for RTL
          right: isRtl ? '0' : '100%', // Adjust starting position for RTL
          height: '0.2rem',
          backgroundColor: 'var(--primary-color)',
          width: '0%', // Start with no visible fill
          zIndex: 2 // Higher z-index for visibility
        }}
      />
    </div>
  )
}

export default Line
