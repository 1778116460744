import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './OutputGallery.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import { color, motion, transform } from 'framer-motion'
import ActionButton from '../Buttons/ActionButton'

const OutputGallery = React.forwardRef(({}, ref) => {
  const { updateSession, curSession, selectedLanguage, setStageByName, onStageEnded } =
    useContext(SessionContext)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [siteData, setSiteData] = useState({})
  const urls = useRef(new Map())

  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrls, setImageUrls] = useState([])
  const [cardDetails, setCardDetails] = useState({})
  const cardRef = useRef() // Array of refs for each card
  const gridRef = useRef()

  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [cardDimensions, setCardDimensions] = useState({})
  const [gridDimensions, setGridDimensions] = useState({})
  const globalTransition = {
    duration: 0.2,
    ease: 'backOut',
    when: 'beforeChildren'
  }

  useEffect(() => {
    if (imagesLoaded) {
      updateDimensions()
    }
  }, [imagesLoaded])

  useEffect(() => {
    handleSiteData()
    getCardDetails()
  }, [selectedLanguage])

  const handleImageLoad = () => {
    if (gridRef.current) {
      const images = gridRef.current.querySelectorAll('img')
      if (Array.from(images).every(img => img.complete)) {
        setImagesLoaded(true)
      }
    }
  }

  const updateDimensions = () => {
    if (gridRef.current) {
      const cardElements = gridRef.current.querySelectorAll('.card')
      if (cardElements.length > 0) {
        const dimensions = {
          width: cardElements[0].offsetWidth,
          height: cardElements[0].offsetHeight
        }
        setCardDimensions(dimensions)
      }

      const gDimensions = {
        width: gridRef.current.offsetWidth,
        height: gridRef.current.offsetHeight
      }
      setGridDimensions(gDimensions)
    } else {
      console.error('Cannot set grid dimensions because grid ref is null')
    }
  }
  useLayoutEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const handleClick = async index => {
    if (selectedImage !== null) return

    const imageUrl = imageUrls[index]
    try {
      const response = await fetch(imageUrl)
      const blob = await response.blob() // Get the blob

      // Call selectImage with the fetched blob
      selectImage(blob)

      // Update the selected image index
      setSelectedImage(index)
    } catch (error) {
      console.error('Failed to fetch image:', error)
    }
    setSelectedImage(index)
  }
  const resetGallery = () => {
    setSelectedImage(null) // Reset the selected image state
  }
  const handleSiteData = async () => {
    const data = await fetchSiteData('resultsGallery')
    setSiteData(data)
  }
  const setEmailStage = () => {
    setStageByName('agePage')
  }

  const setShredStage = () => {
    setStageByName('shred')
  }

  const getCardDetails = async () => {
    const details = await fetchSiteData('selection')
    setCardDetails(details)
    addNameAndYear(details)
  }

  const addNameAndYear = data => {
    if (data) {
      const newName = curSession.name
        ? `${data.title} ${curSession.name}`
        : `${data.title}${selectedLanguage === 'en' ? ' you' : 'ך'}`
      const newYear = `${data.text} ${new Date().getFullYear()}`

      setCardDetails(prevData => ({
        ...prevData, // Spread existing data to maintain other properties
        title: newName, // Update title
        text: newYear // Update text
      }))
    }
  }

  useEffect(() => {
    return () => {
      imageUrls.forEach(url => URL.revokeObjectURL(url))
    }
  }, [imageUrls])

  const selectImage = blob => {
    updateSession({ selectedImageData: blob })
  }

  const backAStage = () => {
    updateSession({ inputImageData: null })
    setStageByName('takePhoto')
  }

  const getTransformOrigin = index => {
    if (index === selectedImage) return 'center center'
    switch (index) {
      case 0:
        return 'top left'
      case 1:
        return 'top right'
      case 2:
        return 'bottom left'
      case 3:
        return 'bottom right'
      default:
        return 'center'
    }
    return 'center center'
  }
  useEffect(() => {
    // Generate new URLs for current session
    let newUrls
    if (curSession.outputImagesData) {
      newUrls = curSession.outputImagesData.map(data => URL.createObjectURL(data.blob))
      setImageUrls(newUrls)
    }
    // Cleanup old URLs
    return () => {
      if (newUrls) {
        newUrls.forEach(url => URL.revokeObjectURL(url))
      }
    }
  }, [curSession.outputImagesData])

  const initialCardState = {
    scale: 1,
    position: 'auto',
    opacity: 1,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    transformOrigin: 'center center' // Default transform origin
  }

  const targetBottomHeight = window.innerHeight * 0.3 // 30vh in pixels

  const getCardAnimationState = index => {
    const cardHeight = cardDimensions.height

    // Calculate translation to align the card's bottom to the target height
    const translateY = targetBottomHeight - cardHeight

    return {
      position: selectedImage === index ? 'relative' : 'static',
      opacity: selectedImage === null || selectedImage === index ? 1 : 0,
      boxShadow: selectedImage === index ? '0 0 2vh rgba(0, 0, 0, 0.12)' : '0 0 2vh rgba(0, 0, 0, 0.0)',
      zIndex: selectedImage === index ? 2 : 1,
      padding: selectedImage === index ? '1vh' : '0vh',
      gap: selectedImage === index ? '0.5vh' : 0,
      height: 'auto',
      transform: selectedImage === index ? 'scale(2)' : selectedImage === null ? 'scale(1)' : 'scale(0)',
      top: selectedImage === index ? (index === 0 || index === 1 ? '59%' : '-30%') : 0,
      left: selectedImage === index ? (index % 2 === 0 ? '50%' : '-50%') : 0,
      transformOrigin: getTransformOrigin(index)
    }
  }

  const initialDetailsState = {
    opacity: 0,
    position: 'absolute',
    translateY: 20 // Start below the card
  }

  const getDetailsAnimationState = index => ({
    opacity: selectedImage === index ? 1 : 0,
    position: selectedImage === index ? 'static' : 'absolute',
    translateY: selectedImage === index ? 0 : 20, // Move to final position
    gap: '0.5vh'
  })
  const initialGridState = {
    gridGap: '2%'
  }
  const animatedGridState = {
    gridGap: selectedImage ? 0 : '2%'
  }

  return (
    <div ref={ref} className='output-gallery-container'>
      <motion.div
        initial={{ opacity: 1, height: 'auto', width: '100%' }}
        animate={{
          opacity: selectedImage !== null ? 0 : 1,
          height: selectedImage !== null ? '6vh' : 'auto'
        }}
        transition={globalTransition}
      >
        <StageHeader siteData={siteData}></StageHeader>
      </motion.div>
      <motion.div
        className='back-container'
        style={{
          scaleX: selectedLanguage === 'he' ? '-1' : '1'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: selectedImage !== null ? 1 : 0 }}
      >
        <svg
          onClick={resetGallery}
          width='3vh'
          style={{
            cursor: 'pointer',
            direction: selectedLanguage === 'he' ? 'rtl' : 'ltr',
            padding: '2dvh 2dvh 2dvh 0'
          }}
          height='32'
          viewBox='0 0 40 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='Group'>
            <path
              id='Vector'
              d='M18.6912 28.8996C18.6912 28.2701 18.4491 27.6648 17.9891 27.1805L8.90922 18.1491H37.1899C38.5216 18.1491 39.6112 17.0837 39.6112 15.7278C39.6112 14.3718 38.5216 13.3065 37.1899 13.3065L8.90922 13.3065L17.9649 4.25082C18.9092 3.30652 18.9092 1.7811 17.9649 0.836794C17.0205 -0.107513 15.495 -0.107513 14.5507 0.836794L1.81481 13.5244C1.50004 13.8392 1.28208 14.2508 1.1368 14.6624C0.991524 14.9772 0.870483 15.3404 0.870483 15.7036C0.870483 16.0668 0.967311 16.43 1.1368 16.7447C1.28208 17.1563 1.50004 17.568 1.81481 17.8827L14.5507 30.5703C15.495 31.5146 17.0205 31.5146 17.9649 30.5703C18.4249 30.0861 18.667 29.4807 18.667 28.8512L18.6912 28.8996Z'
              fill='#42718B'
            />
          </g>
        </svg>
      </motion.div>
      <motion.div className='horizontaly-center'>
        <div className='grid-ghost'></div>
        <motion.div
          ref={gridRef}
          className='gallery-container'
          initial={initialGridState}
          animate={animatedGridState}
        >
          {imageUrls.map((url, index) => (
            <motion.div
              ref={cardRef}
              key={url} // Ideally, use a unique identifier instead of index
              className='card'
              onClick={() => handleClick(index)}
              initial={initialCardState}
              animate={getCardAnimationState(index)}
              transition={{ duration: 0.2, ease: 'easeInOut', when: 'beforeChildren' }}
              layoutId={selectedImage === index ? 'selected' : undefined}
            >
              <motion.img key={index} src={url} onLoad={handleImageLoad} className='gallery-image' />
              <motion.div
                className='details'
                style={{ direction: selectedLanguage === 'he' ? 'rtl' : 'ltr' }}
                initial={initialDetailsState}
                animate={getDetailsAnimationState(index)}
                transition={globalTransition}
              >
                <div className='card-title'>{cardDetails.title}</div>
                <div className='card-text'>{cardDetails.text}</div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          className='action-buttons-parent'
          initial={{ opacity: 0, height: 'auto' }}
          animate={{ height: 'auto', opacity: selectedImage !== null ? 1 : 0 }}
          transition={{ duration: 0.2, ease: 'backOut' }}
        >
          <ActionButton
            buttonType={'standard shared-styles'}
            onClick={setEmailStage}
            text={siteData.buttonRight}
          >
            <svg width='2.9vh' viewBox='0 0 57 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g id='Icon / Essential / Heart'>
                <path
                  id='Vector'
                  d='M28.78 49.2718C27.7902 49.2718 26.8243 49.0216 25.9287 48.5213C15.4181 42.5423 5.16675 35.5626 5.16675 21.7784C5.16675 15.174 8.96094 9.79551 15.041 7.76916C20.0606 6.09304 25.2215 7.21874 28.6858 10.546C31.7965 7.36884 36.6983 6.21805 41.7415 7.69403C48.2222 9.5953 52.3934 15.099 52.3934 21.7784C52.3934 34.712 42.4249 42.2671 31.6315 48.5213C30.736 49.0466 29.7465 49.2968 28.7567 49.2968L28.78 49.2718ZM19.6835 11.997C18.5759 11.997 17.4683 12.197 16.4549 12.5222C14.9231 13.0476 9.88 15.224 9.88 21.7534C9.88 31.7601 16.5728 37.5139 28.144 44.0933C28.521 44.3185 29.0157 44.3185 29.3927 44.0933C38.8899 38.5896 47.7037 32.0853 47.7037 21.7534C47.7037 16.4749 43.9805 13.523 40.5163 12.4973C37.2641 11.5467 32.9041 12.047 31.0188 15.3992C30.5475 16.2247 29.7462 16.725 28.8507 16.75C27.9552 16.7751 27.1304 16.3248 26.6119 15.5493C24.9387 12.9976 22.2522 11.9719 19.6599 11.9719L19.6835 11.997Z'
                  fill='white'
                />
              </g>
            </svg>
          </ActionButton>
          <ActionButton
            buttonType={'inverted shared-styles'}
            onClick={setShredStage}
            text={siteData.buttonLeft}
          >
            <svg width='2.9dvh' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M43.792 11.2897H36.9152C36.7808 7.52645 33.712 4.54736 29.9488 4.54736H26.0064C22.2432 4.54736 19.1968 7.54896 19.04 11.2674H12.1632C10.976 11.357 10.0576 12.3202 10.0576 13.5074C10.0576 14.6946 11.0656 15.7474 12.2976 15.7474H12.4096L13.776 40.8353C13.776 45.5393 17.584 49.3473 22.288 49.3473H33.6672C38.3488 49.3473 42.1792 45.5169 42.1792 40.9473L43.5456 15.7474H43.6576C44.8896 15.7474 45.8976 14.7394 45.8976 13.5074C45.8976 12.2754 44.9568 11.3569 43.792 11.2897ZM26.0288 9.02736H29.9712C31.248 9.02736 32.2784 10.013 32.4352 11.2674H23.5872C23.7216 10.013 24.752 9.02736 26.0512 9.02736H26.0288ZM37.7216 40.8353C37.7216 43.0529 35.9072 44.8673 33.6896 44.8673H22.3104C20.0928 44.8673 18.2784 43.0529 18.2784 40.7233L16.9344 15.7474H39.088L37.744 40.8353H37.7216Z'
                fill='#78A2B9'
              />
              <path
                d='M23.4539 20.1821C22.2219 20.1821 21.2139 21.1901 21.2139 22.4221V38.2141C21.2139 39.4461 22.2219 40.4541 23.4539 40.4541C24.6859 40.4541 25.6939 39.4461 25.6939 38.2141V22.4221C25.6939 21.1901 24.6859 20.1821 23.4539 20.1821Z'
                fill='#78A2B9'
              />
              <path
                d='M32.5046 20.1821C31.2726 20.1821 30.2646 21.1901 30.2646 22.4221V29.1869C30.2646 30.4189 31.2726 31.4269 32.5046 31.4269C33.7366 31.4269 34.7446 30.4189 34.7446 29.1869V22.4221C34.7446 21.1901 33.7366 20.1821 32.5046 20.1821Z'
                fill='#78A2B9'
              />
            </svg>
          </ActionButton>
        </motion.div>
      </motion.div>
    </div>
  )
})

export default OutputGallery
