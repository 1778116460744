import React, { useContext, useEffect, useState } from 'react'
import './NavigationToolbar.css'
import { SessionContext } from '../../contexts/SessionContext'
import { AnimatePresence, motion } from 'framer-motion'
import Line from './Line'

const NavigationToolbar = () => {
  const { selectedLanguage, takingPhoto, currentStage } = useContext(SessionContext)
  const [steps, setSteps] = useState([])
  const stepsAmount = 4
  const activeStages = ['takePhoto', 'generating', 'shred', 'resultsGallery', 'agePage']
  // Animation variants for fading in and out
  const fadeInOut = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.5 }
    }
  }

  const [isIOS, setIsIOS] = useState(false)

  useEffect(() => {
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
  }, [])

  // Function to update steps based on currentStage
  const updateStepStatuses = () => {
    let newSteps = Array(stepsAmount).fill('idle') // Start with all steps as idle

    switch (currentStage.name) {
      case 'home':
        // Example: Set the first step to selected when at home
        //newSteps[0] = 'selected'
        break
      case 'takePhoto':
        newSteps[1] = 'selected' // Previous steps completed
        newSteps[0] = 'completed' // Previous steps completed
        for (let i = 2; i < stepsAmount; i++) {
          newSteps[i] = 'idle' // Future steps idle
        }
        break
      case 'generating':
        newSteps[2] = 'selected' // Previous steps completed
        newSteps[1] = 'completed' // Previous steps completed
        newSteps[0] = 'completed' // Previous steps completed
        for (let i = 3; i < stepsAmount; i++) {
          newSteps[i] = 'idle' // Future steps idle
        }
        break
      case 'resultsGallery':
        for (let i = 3; i > -1; i--) {
          newSteps[i] = 'completed' // Future steps idle
        }
        newSteps[3] = 'selected'
        break
      case 'agePage':
        for (let i = 3; i > -1; i--) {
          newSteps[i] = 'completed' // Future steps idle
        }
        break
      case 'shred':
        for (let i = 3; i > -1; i--) {
          newSteps[i] = 'completed' // Future steps idle
        }
        break
      default:
        break
    }

    setSteps(newSteps)
  }

  useEffect(() => {
    updateStepStatuses()
  }, [currentStage.index, stepsAmount]) // Ensure it re-runs when currentStage.index or stepsAmount changes

  // Render buttons based on the steps state
  const stepButtons = steps.map((status, index) => {
    // Determine the line class before the next button
    // Check if the next button's status is 'idle', if it exists
    let lineClass = index < steps.length - 1 && steps[index + 1] === 'idle' ? 'dotted' : 'full'

    return (
      // Fragment to wrap multiple elements without adding extra nodes to the DOM
      <React.Fragment key={index}>
        <button
          className={`navigation-button ${status}`}
          // style={isIOS ? { width: '32.19px', height: '32.19px' } : { height: '100%', aspectRatio: '1/1' }}
        >
          {status !== 'completed' && (index + 1).toString().padStart(2, '0')}
          {status === 'completed' && (
            <svg width='1.679166dvh' viewBox='0 0 32 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10.156 19.284L29.44 0L32.0005 2.5605L10.156 24.405L-0.000488281 14.2515L2.56001 11.691L10.156 19.284Z'
                fill='white'
              />
            </svg>
          )}
        </button>
        {/* Only render line if it is not the last item */}
        {index < steps.length - 1 && (
          <Line isFilled={steps[index] === 'completed'} isRtl={selectedLanguage === 'he'} />
        )}
      </React.Fragment>
    )
  })

  return (
    <AnimatePresence>
      <motion.div
        className='navigation-toolbar'
        style={{
          visibility: activeStages.find(s => s === currentStage.name) ? 'visible' : 'hidden',
          direction: selectedLanguage === 'he' ? 'rtl' : 'ltr'
          // height: isIOS ? '96.56px' : '9vh'
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: takingPhoto ? 0 : 1 }}
        transition={{ duration: 0.5 }} // Customize the duration of the transition
      >
        {stepButtons}
      </motion.div>
    </AnimatePresence>
  )
}
export default NavigationToolbar

/* <button className='navigation-button completed'>
        <Icon iconType={'v'}></Icon>
      </button>
      <div className='line'></div>
      <button className='navigation-button selected'>02</button>
      <div className='dotted'></div>
      <button className='navigation-button idle'></button>
      <div className='dotted'></div>
      <button className='navigation-button idle'></button> */
