import React from 'react'

const processText = (text, className) => {
  if (!text) return
  const regex = /\$\{([^}]+)\}/g
  let lastIndex = 0
  const elements = []

  let match
  while ((match = regex.exec(text)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      elements.push(text.slice(lastIndex, match.index))
    }
    // Add the wrapped span
    elements.push(
      <span className={className} key={match.index}>
        {match[1]}
      </span>
    )
    lastIndex = regex.lastIndex
  }

  // Add remaining text after the last match
  if (lastIndex < text.length) {
    elements.push(text.slice(lastIndex))
  }

  return elements
}

export default processText
