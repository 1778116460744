import React, { useContext, useEffect, useState } from 'react'
import './AdminPage.css'
import axios from 'axios'
import { AuthContext } from '../../contexts/AuthContext'
import LoginComponent from '../Authentication/Login'

const AdminPage = () => {
  const [data, setData] = useState([])
  const { isAdminAuthenticated, login } = useContext(AuthContext)
  const [token, setToken] = useState(null)

  const handleLogin = token => {
    setToken(token)
    login(token, true) // Update the authentication state in AuthContext
  }
  const replaceHostAndPort = url => {
    try {
      const newHost = 'api.bac3.org.il'
      const urlObj = new URL(url)
      urlObj.hostname = newHost
      urlObj.port = ''
      return urlObj.toString()
    } catch (error) {
      console.error('Invalid URL', error)
      return url
    }
  }

  useEffect(() => {
    if (isAdminAuthenticated) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/data`, {
          headers: {
            Authorization: `Bearer ${token}` // Add token if needed for authentication
          }
        })
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.error('There was an error fetching the admin data!', error)
        })
    }
  }, [isAdminAuthenticated, token])
  return (
    <>
      {isAdminAuthenticated ? (
        <div className='grid-container'>
          {data.map(item => (
            <div key={item._id} className='grid-item'>
              <div className='admin-text'>
                <p>
                  <strong style={{ fontWeight: '700' }}>Full Name:</strong>{' '}
                  {item.name || `${item.form.firstName} ${item.form.lastName}` || 'N/A'}
                </p>
                <p>
                  <strong style={{ fontWeight: '700' }}>Email:</strong>{' '}
                  {item.email || item.form.email || 'N/A'}
                </p>
                <p>
                  <strong style={{ fontWeight: '700' }}>Mobile number:</strong>{' '}
                  {item.mobileNumber || item.form.mobileNumber || 'N/A'}
                </p>
                <p>
                  <strong style={{ fontWeight: '700' }}>Subscribed:</strong>{' '}
                  {item.isSubscribed || item.form.isSubscribed ? 'True' : 'False'}
                </p>
                <p>
                  <strong style={{ fontWeight: '700' }}>Underage:</strong>{' '}
                  {item.isUnderage || item.form.isUnderage ? 'True' : 'False'}
                </p>
                <p>
                  <strong style={{ fontWeight: '700' }}>Date:</strong> {item.dateTime}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <LoginComponent handleLogin={handleLogin} handleLogout={()=>{}} role={'admin'} />
      )}
    </>
  )
}

export default AdminPage
