// InactivityTimer.js
import React, { useState, useEffect, useCallback, useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'

export const InactivityTimer = ({ timeout, onTimeout }) => {
  const [timer, setTimer] = useState(timeout)
  const { currentStage } = useContext(SessionContext) // Access the currentStage from context

  const resetTimer = useCallback(() => {
    setTimer(timeout)
  }, [timeout])

  const handleActivity = useCallback(() => {
    resetTimer()
  }, [resetTimer])

  useEffect(() => {
    window.addEventListener('click', handleActivity)

    const interval = setInterval(() => {
      setTimer(prevTimer => {
        // Check if currentStage.name is 'generating', if so, don't decrement the timer
        if (currentStage.name === 'generating' || currentStage.name === 'home') {
          return prevTimer // Return previous timer value to pause countdown
        }
        if (prevTimer <= 1) {
          clearInterval(interval)
          setTimeout(onTimeout, 0) // Schedule state update after the render phase
          return 0
        }
        return prevTimer - 1
      })
    }, 1000)

    // Cleanup function to clear interval and event listeners
    return () => {
      clearInterval(interval)
      window.removeEventListener('click', handleActivity)
    }
  }, [handleActivity, onTimeout, timeout, currentStage.name]) // Include currentStage.name in the dependencies array

  return null // This component does not render anything
}
