import React, { useContext, useEffect, useState } from 'react'
import './TermsAndConditions.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'

const TermsAndConditions = ({ setActive, style }) => {
  // const { fetchSiteData } = useContext(SiteDataContext)
  // const [siteData, setSiteData] = useState({
  //   title: '',
  //   text: '',
  //   additionalText: '',
  //   buttonLeft: ''
  // })

  // useEffect(() => {
  //   handleSiteData()
  // }, [])
  // useEffect(() => {}, [siteData])

  // const handleSiteData = async () => {
  //   const data = await fetchSiteData('terms')
  //   setSiteData(data)
  // }

  const closeWindow = () => {
    //throws the error setActive is not a function
    setActive()
  }

  return (
    <div className='container' style={style}>
      <div className='close-button-container'>
        <svg
          onClick={closeWindow}
          className='x-button'
          width='1.7vh'
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12.1439 0.672651L7.1254 5.69114L2.10691 0.672651C1.7126 0.278341 1.06736 0.278341 0.673052 0.672651C0.278742 1.06696 0.278742 1.7122 0.673052 2.10651L5.69155 7.125L0.673052 12.1435C0.278742 12.5378 0.278742 13.183 0.673052 13.5773C1.06736 13.9717 1.7126 13.9717 2.10691 13.5773L7.1254 8.55886L12.1439 13.5773C12.5382 13.9717 13.1834 13.9717 13.5778 13.5773C13.9721 13.183 13.9721 12.5378 13.5778 12.1435L8.55926 7.125L13.5778 2.10651C13.9721 1.7122 13.9721 1.06696 13.5778 0.672651C13.1834 0.27834 12.5382 0.278341 12.1439 0.672651Z'
            fill='#42718B'
          />
        </svg>
      </div>
      <div className='terms-text'>
        אנא קרא/י בעיון את התנאים להלן ("תנאי השימוש") לפני אישורך ושימושך במערכת. אם אינך מסכים/ה לתנאי
        השימוש, אנא אל תעשה/י שימוש במערכת.
        <br />
        <br />
        אני מצהיר/ה, מאשר/ת ומסכימ/ה כדלקמן:
        <br />
        <br />
        <div className='section'>
          1.
          <p className='section-text'>
            אני מעוניינ/ת ומסכימ/ה להצטלם במסגרת חוויה אינטרקטיבית בתערוכת ליטבינובסקי המתקיימת בבית אבי חי
            ("התערוכה") ומתיר/ה לעמותת בית אבי חי ע.ר. ו/או מי מטעמה (להלן– "העמותה") לצלם את דמותי, באמצעות
            עמדת הצילום בתערוכה ו/או בכל אמצעי אחר (להלן – "הצילום").
          </p>
        </div>
        <div className='section'>
          2.
          <p className='section-text'>
            אני מסכימ/ה כי ייעשה שימוש בצילום במסגרת מערכת "מחולל הדיוקנאות", הפועלת בטכנולוגיית AI (להלן –
            "המחולל"). המחולל יחולל דיוקנאות שלי (להלן – "הדיוקנאות") על בסיס הצילום בשילוב הסגנון הציורי ו/או
            אלמנטים ו/או חלקים מתוך תמונותיו של האמן פנחס ליטבינובסקי (להלן – "תמונות ליטבינובסקי"), וזאת
            באמצעות טכנולוגיית AI. בכלל זה אני מסכימ/ה כי יבוצעו בצילום עריכה ו/או עיבוד ו/או שינוי ו/או תוספת
            ו/או כל פעולה אחרת שתתבצע בצילום במחולל.
          </p>
        </div>
        <div className='section'>
          3.
          <p className='section-text'>
            לאחר סיום הפקת הדיוקנאות על ידי המחולל, תציג בפני מערכת המחולל את הדיוקנאות שיצרה, ותאפשר לי לבחור
            את הדיוקן המועדף עלי – ודיוקן זה ישלח אלי אל כתובת המייל שהזנתי.
          </p>
        </div>
        <div className='section'>
          4.
          <p className='section-text'>
            לאחר משלוח הדיוקן (כמו גם במקרה שלא בחרתי כל דיוקן) - המערכת תמחק את הצילום ואת כלל הדיוקנאות
            שהופקו והם לא ישמרו במערכת.
          </p>
        </div>
        <div className='section'>
          5.
          <p className='section-text'>
            ככל שאשתף או אפרסם את הדיוקן, אני מתחייב לשתפו או לפרסמו כפי שנשלח אלי ללא שינוי וכן אני מתחייב לא
            להסיר את הכיתוב ו/או הלוגו המופיעים על הדיוקן בנוגע לבית אבי חי ו/או התערוכה, ככל שאלו מופיעים בו,
            ולוודא שבכל פרסום או שיתוף יופיעו הכיתוב ו/או הלוגו כפי שנכללו בדיוקן שנשלח אלי.
          </p>
        </div>
        <div className='section'>
          6.
          <p className='section-text'>אני מאשר שהפרטים שלי אותם מסרתי לעמותה, הם נכונים ומלאים.</p>
        </div>
        <div className='section'>
          7.
          <p className='section-text'>
            ידוע לי ואני מאשר בזאת כי פרטיי יישמרו במאגר מידע רשום, בבעלות העמותה ששמו "מאגר הלקוחות של בית
            אבי חי" ומספרו 700018875 ("מאגר המידע").
          </p>
        </div>
        <div className='section'>
          8.
          <p className='section-text'>
            ידוע לי כי לא חלה עלי כל חובה חוקית למסור לעמותה את פרטיי, והדבר תלוי ברצוני החופשי והסכמתי. ככל
            שבחרתי לעשות שימוש במערכת, הרי בכך אני מסכימ/ה לשמירת פרטיי במאגר המידע. השימוש במידע שנאסף ונאגר
            במאגר המידע ייעשה על פי הוראות כל דין, ובכלל זה לצרכי שיווק, וככל שבחרתי בכך, גם לצורך משלוח
            עדכונים וקשר עמי. המידע שמסרתי, כולו או חלקו, יכול שיוחזק בפועל על ידי ספקים חיצוניים של העמותה
            להם נתונה גישה למאגר המידע לצרכים טכניים ומנהליים. אהיה רשאי לבקש את מחיקת פרטי בכל עת, בהתאם
            לסעיף 10 להלן.
          </p>
        </div>
        <div className='section'>
          9.
          <p className='section-text'>
            העמותה מציעה שירות קבלת ניוזלטרים, עדכונים ותכנים שונים לדוא"ל או באמצעות הודעותSMS (דיוור). דיוור
            ישלח מידי פעם ויכלול מידע, עדכונים ופרסומים שונים לגבי האירועים, המופעים, הפרויקטים ופעילויות בית
            אבי חי (להלן: "השירות"). ההצטרפות לשירות הינה חינם ונעשית באמצעות סימון אישור לכך במקום שהוצג לי
            על ידי המערכת. ככל שיחול שינוי בפרטיי, באפשרותי לפנות לכתובת הדוא"ל info@bac.org.il כדי לעדכנם. אי
            עדכון עלול לגרום לכך שהעמותה לא תוכל לספק לי את השירות. העמותה תהיה רשאית להחליט בכל עת על הפסקת
            השירות או להימנע מלהעניקו לפי שיקול דעתה.
          </p>
        </div>
        <div className='section'>
          10.
          <p className='section-text'>
            ידוע לי כי אהיה רשאי לבקש להימחק מרשימת הדיוור ו/או ממאגר המידע בכפוף להוראות כל דין, באמצעות
            משלוח הודעה לכתובת הדוא"ל: info@bac.org.il (או, בנוגע לדיוור ישיר - באמצעות לחיצה על הקישור "הסרה"
            המופיע בדיוור שנשלח).
          </p>
        </div>
        <div className='section'>
          11.
          <p className='section-text'>
            את המידע שישמש לצורך שירות הדיוור – כתובת הדוא"ל ומספר הטלפון תהיה העמותה רשאית, בכפוף לכל דין,
            להעביר לגופים אחרים לצורך התאמה ושיפור בתנאי השירות, ובתנאי כי הגוף השלישי אליו יועברו הפרטים לא
            יעשה בהם שימוש מעבר למותר לו על פי החוק, ישמור על סודיותם ולא יעבירם לגוף אחר כלשהו ללא הסכמת
            העמותה. אני מסכים ומאשר כי שימוש כזה זה לא ייחשב כפגיעה בפרטיותי.
          </p>
        </div>
        <div className='section'>
          12.
          <p className='section-text'>
            אני מאשר/ת כי כל הסכמותיי והתחייבויותיי כאמור במסמך זה, ניתנות ללא תמורה, כחלק מהשתתפותי בחוויה
            האינטרקטיבית שמציעה העמותה בתערוכת ליטבינובסקי.
          </p>
        </div>
        <p />
      </div>
    </div>
  )
}

export default TermsAndConditions
