import React, { useContext, useEffect, useRef, useState } from 'react'
import '../InputEmail/InputEmail.css'
import ActionButton from '../Buttons/ActionButton'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'

const PasscodeSend = React.forwardRef(({}, ref) => {
  const { fetchSiteData } = useContext(SiteDataContext)
  const { updateSession, selectedLanguage, setStageByName, curSession } = useContext(SessionContext)
  const [siteData, setSiteData] = useState({})
  const [isValid, setIsValid] = useState(false)
  const [form, setForm] = useState({
    email: '',
    mobileNumber: '',
    firstName: '',
    lastName: '',
    isUnderage: true,
    isSubscribed: false
  })
  const [canSubmit, setCanSubmit] = useState(false)
  const [formFields, setFormFields] = useState({
    firstName: { value: '', ref: useRef(), touched: false },
    lastName: { value: '', ref: useRef(), touched: false },
    email: { value: '', ref: useRef(), touched: false }
  })

  const handleBlur = field => () => {
    setFormFields(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        touched: true,
        value: prev[field].value.trim() === '' ? 'left form unfilled' : prev[field].value
      }
    }))
  }

  useEffect(() => {
    if (curSession.passcode) {
      handleSiteData('passcodeSend')
    } else {
      handleSiteData('parentForm')
    }
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async stage => {
    const data = await fetchSiteData(stage)
    setSiteData(data)
  }

  const handleBack = () => {
    setStageByName('shred')
  }

  useEffect(() => {}, [curSession])

  const handleSubmit = e => {
    e.preventDefault()
    updateSession({ form: form })
    setStageByName('emailSending')
  }

  const isValidEmail = email => {
    // Trim the email to remove leading/trailing whitespace
    const cleanedEmail = email.trim()

    // Email validation pattern
    const regexPattern =
      /^(?:(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|(".+"))@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/i

    // Test the cleaned email against the regex
    const valid = regexPattern.test(cleanedEmail)

    // Set the validation state, assume setIsValid is a React state setter
    setIsValid(valid)

    return valid
  }

  useEffect(() => {
    if (form.email && isValidEmail(form.email) && form.firstName && form.lastName) {
      setCanSubmit(true)
    } else {
      //
      setCanSubmit(false)
    }
  }, [form]) // Depend on form state so this runs whenever form state changes

  const inputStyle = field => ({
    borderColor:
      formFields[field].touched && formFields[field].value === 'left form unfilled' ? '#F36666' : '#D0D9DD'
  })

  const inputTitleStyle = field => {
    return formFields[field].touched && formFields[field].value === 'left form unfilled'
      ? {
          //can I also place an svg here before the actual text?
          backgroundColor: '#F36666',
          color: 'white',
          boxSizing: 'border-box',
          gap: '0.rem',
          padding: '0 .5rem'
        }
      : {
          backgroundColor: 'transparent',
          color: 'var(--title-font-color)'
        }
  }
  return (
    <div ref={ref}>
      <StageHeader siteData={siteData}></StageHeader>
      <div className='input-email-container'>
        <div className='forms-container' style={{ direction: selectedLanguage === 'he' ? 'rtl' : 'ltr' }}>
          <div className='forms-bundle'>
            <div className='form-package'>
              <div className='form-title' style={inputTitleStyle('firstName')}>
                {formFields['firstName'].touched && formFields['firstName'].value === 'left form unfilled' ? (
                  <>
                    <svg
                      style={{ overflow: 'visible' }}
                      width='0.9rem'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                        fill='white'
                      />
                      <path
                        d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                        fill='white'
                      />
                      <path
                        d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                        fill='white'
                      />
                    </svg>
                    <span>{siteData.additionalText8}</span>
                  </>
                ) : (
                  <>
                    <span style={{ color: 'red' }}>*</span>
                    <span>{siteData.additionalText1}</span>
                  </>
                )}
              </div>
              <form
                onSubmit={e => {
                  handleSubmit(e)
                }}
                style={{ width: '100%' }}
              >
                <input
                  type='text'
                  value={form.firstName}
                  ref={formFields['firstName'].ref}
                  onBlur={handleBlur('firstName')}
                  onChange={e => {
                    setForm(prev => ({ ...prev, firstName: e.target.value }))
                    setFormFields(prev => ({
                      ...prev,
                      ['firstName']: { ...prev['firstName'], value: e.target.value, touched: false }
                    }))
                  }}
                  placeholder={siteData.additionalText1}
                  className='input-field'
                  style={inputStyle('firstName')}
                />
              </form>
            </div>

           {/* lastName */}
            <div className='form-package'>
              <div className='form-title' style={inputTitleStyle('lastName')}>
                {formFields['lastName'].touched && formFields['lastName'].value === 'left form unfilled' ? (
                  <>
                    <svg width='0.9rem' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                        fill='white'
                      />
                      <path
                        d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                        fill='white'
                      />
                      <path
                        d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                        fill='white'
                      />
                    </svg>
                    <span>{siteData.additionalText1}</span>
                  </>
                ) : (
                  <>
                    <span style={{ color: 'red' }}>*</span>
                    <span>{siteData.additionalText2}</span>
                  </>
                )}
              </div>
              <form
                onSubmit={e => {
                  handleSubmit(e)
                }}
                style={{ width: '100%' }}
              >
                <input
                  type='text'
                  ref={formFields['lastName'].ref}
                  onBlur={handleBlur('lastName')}
                  value={form.lastName}
                  onChange={e => {
                    setForm(prev => ({ ...prev, lastName: e.target.value }))
                    setFormFields(prev => ({
                      ...prev,
                      ['lastName']: { ...prev['lastName'], value: e.target.value, touched: false }
                    }))
                  }}
                  placeholder={siteData.additionalText2}
                  className='input-field'
                  style={inputStyle('lastName')}
                />
              </form>
            </div>
          </div>
          <div className='form-package'>
            <div className='form-title' style={inputTitleStyle('email')}>
              {formFields['email'].touched && formFields['email'].value === 'left form unfilled' ? (
                <>
                  <svg width='0.9rem' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M12.0006 14.6166C12.7204 14.6166 13.304 14.033 13.304 13.3133V7.37075C13.304 6.65087 12.7204 6.06738 12.0006 6.06738C11.2809 6.06738 10.6973 6.65087 10.6973 7.37075V13.3134C10.6973 14.0331 11.2809 14.6166 12.0006 14.6166Z'
                      fill='white'
                    />
                    <path
                      d='M12.0006 15.3262C11.6576 15.3262 11.3207 15.4648 11.0788 15.7078C10.8359 15.9497 10.6973 16.2854 10.6973 16.6285C10.6973 16.9715 10.8359 17.3083 11.0788 17.5502C11.3207 17.7932 11.6576 17.9319 12.0006 17.9319C12.3437 17.9319 12.6794 17.7932 12.9224 17.5502C13.1642 17.3083 13.304 16.9715 13.304 16.6285C13.304 16.2854 13.1642 15.9497 12.9224 15.7078C12.6794 15.4648 12.3437 15.3262 12.0006 15.3262Z'
                      fill='white'
                    />
                    <path
                      d='M12.0001 2C6.48608 2 2 6.48598 2 12.0001C2 17.5141 6.48598 22.0001 12.0001 22.0001C17.5141 22.0001 22 17.5141 22 12.0001C22 6.48598 17.5141 2 12.0001 2ZM12.0001 20.4361C7.34839 20.4361 3.56404 16.6517 3.56404 12.0001C3.56404 7.34839 7.34839 3.56404 12.0001 3.56404C16.6516 3.56404 20.436 7.34839 20.436 12.0001C20.436 16.6517 16.6517 20.4361 12.0001 20.4361Z'
                      fill='white'
                    />
                  </svg>
                  <span>{siteData.additionalText8}</span>
                </>
              ) : (
                <>
                  <span style={{ color: 'red' }}>*</span>
                  <span>{siteData.additionalText}</span>
                </>
              )}
            </div>
            <form
              onSubmit={e => {
                handleSubmit(e)
              }}
              style={{ width: '100%' }}
            >
              <input
                type='email'
                ref={formFields['email'].ref}
                onBlur={handleBlur('email')}
                value={form.email}
                onChange={e => {
                  setForm(prev => ({ ...prev, email: e.target.value }))
                  setFormFields(prev => ({
                    ...prev,
                    ['email']: { ...prev['email'], value: e.target.value, touched: false }
                  }))
                }}
                placeholder={siteData.additionalText}
                className='input-field'
                style={inputStyle('email')}
              />
            </form>
          </div>{' '}
        </div>
        <div className='action-buttons-parent'>
          <ActionButton
            buttonType={'standard shared-styles'}
            text={siteData.buttonRight}
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            <svg
              width='2.5vh'
              height='2.5vh'
              viewBox='0 0 56 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g id='Icon/ Heart'>
                <g id='Group'>
                  <path
                    id='Vector'
                    d='M45.4359 11.6667H10.5647C7.31279 11.6667 4.66699 14.3125 4.66699 17.5645V39.9521C4.66699 43.204 7.31279 45.8498 10.5647 45.8498H45.4359C48.6879 45.8498 51.3337 43.204 51.3337 39.9521V17.5645C51.3337 14.3125 48.6879 11.6667 45.4359 11.6667ZM44.5145 15.4711L28.0003 28.2281L11.4862 15.4711H44.5145ZM45.4359 42.0457H10.5647C9.41051 42.0457 8.47134 41.1066 8.47134 39.9523V17.9495L26.8375 32.1372C27.1799 32.4017 27.5902 32.5338 28.0003 32.5338C28.4104 32.5338 28.8208 32.4017 29.1632 32.1372L47.5293 17.9495V39.9521C47.5293 41.1066 46.5901 42.0457 45.4359 42.0457Z'
                    fill='white'
                  />
                </g>
              </g>
            </svg>
          </ActionButton>
          <ActionButton buttonType={'inverted shared-styles'} onClick={handleBack}>
            {siteData.buttonLeft}
          </ActionButton>
        </div>
      </div>
    </div>
  )
})

export default PasscodeSend
