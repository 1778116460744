import React, { useContext, useEffect, useRef, useState } from 'react'
import './EmailSending.css'
import { SessionContext, SiteDataContext } from '../../contexts/SessionContext'
import StageHeader from '../StageHeader/StageHeader'
import { useSocket } from '../../contexts/SocketContext'

const EmailSending = React.forwardRef(({}, ref) => {
  const { curSession, selectedLanguage, setStageByName, deleteSession } = useContext(SessionContext)
  const { fetchSiteData } = useContext(SiteDataContext)
  const [overlay, setOverlay] = useState(true)
  const executing = useRef(false)
  const socket = useSocket()

  const [siteData, setSiteData] = useState({})

  useEffect(() => {
    handleSiteData()
  }, [selectedLanguage])
  useEffect(() => {}, [siteData])

  const handleSiteData = async () => {
    let data
    if (curSession.isUnderage) {
      if (curSession.passcode) {
        data = await fetchSiteData('emailSending')
      } else {
        data = await fetchSiteData('parentFormSending')
      }
    } else {
      data = await fetchSiteData('emailSending')
    }
    setSiteData(data)
  }

  const onSuccess = async () => {
    //setOverlay(false)
    //update the number of created images -
    await updateCreatedPortraitsAmount()
    setStageByName('emailSuccess')
    executing.current = false
  }
  const onFail = () => {
    executing.current = false
    setOverlay(false)
    console.error(`Session ending sequence failed.`)
    setTimeout(() => {
      setStageByName('errorPage')
    }, 1000)
  }

  const updateCreatedPortraitsAmount = async () => {
    socket.emit('image_status', { saved: true })
  }

  useEffect(() => {
    if (!executing.current && curSession.form) {
      executing.current = true

      //packAndSendSession(onFail, onSuccess)
      let packedSession = {}
      //set date and time
      const currentdate = new Date()
      packedSession.dateTime =
        currentdate.getDate() +
        '_' +
        (currentdate.getMonth() + 1) +
        '_' +
        currentdate.getFullYear() +
        '_' +
        currentdate.getHours() +
        '_' +
        currentdate.getMinutes() +
        '_' +
        currentdate.getSeconds() +
        '_' +
        currentdate.getMilliseconds()

      const blob = curSession.selectedImageData
      const formData = new FormData()
      const fileName = 'Litvinovsky_Portrait' + '_' + packedSession.dateTime + '.png'
      
      if (blob instanceof Blob) {
        formData.append('image', blob, fileName);
      } else {
        console.error('The provided object is not a Blob.');
        // Handle the error accordingly, e.g., show a notification to the user
        deleteSession()
        setStageByName('errorPage')
        return
      }
      //migrate this logic to be based on socket io
      //wait for the image upload -
      //deprecated now with the move to emails based on blobs
      // socket.emit('submit_generated_image', {
      //   image: formData.get('image'),
      //   fileName: fileName
      // })

      packedSession.form = curSession.form

      packedSession.form.firstName = curSession.form.firstName.replace(/\s/g, '_')

      packedSession.form.lastName = curSession.form.lastName.replace(/\s/g, '_')
      // socket.on('generated_image_submitted', data => {
      //   packedSession.selectedImageUrl = data.imageUrl
      //   socket.emit('submit_session', packedSession)
      //   })

      packedSession.imageBlob = blob
      if (curSession.isUnderage && !curSession.passcode) {
        packedSession.waitForParentalApproval = true
      }

      socket.emit('submit_session_and_image', packedSession)

      socket.on('session_submission_success', data => {
        console.log('email success!')
        if (process.env.NODE_ENV === 'development') {
          setTimeout(() => {
            onSuccess()
          }, 500)
        } else {
          onSuccess()
        }
      })
      socket.on('upload_error', error => {
        console.error('Error submitting image:', error.message)
        onFail()
      })
    }

    return () => {
      if (!executing.current) {
        socket.off('generated_image_submitted')
        socket.off('session_submission_success')
        socket.off('upload_error')
      }
    }
  }, [curSession])

  return (
    <div ref={ref}>
      <StageHeader siteData={siteData}></StageHeader>
      <div className='horizontaly-center'>
        <img className='shred-gif' src={`${process.env.REACT_APP_IMAGES_URL}/sending-email.gif`} />
      </div>
    </div>
  )
})

export default EmailSending
